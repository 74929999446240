import React, { Component } from 'react'
import TitleSubtitleDescription from '../text/TitleSubtitleDescription'
import satellite from '../../assets/img/satellite.png'

export default class Rtms extends Component {
    componentDidMount() {
        const $card = document.querySelector('.ThreeD__card');
        let bounds;
        
        function rotateToMouse(e) {
          const mouseX = e.clientX;
          const mouseY = e.clientY;
          const leftX = mouseX - bounds.x;
          const topY = mouseY - bounds.y;
          const center = {
            x: leftX - bounds.width / 3,
            y: topY - bounds.height / 3
          }
          const distance = Math.sqrt(center.x**2 + center.y**2);
          
          $card.style.transform = `
            rotate3d(
              ${center.y / 200},
              ${-center.x / 200},
              0,
              ${Math.log(distance)* 3}deg
            )
          `;
        }
        
        $card.addEventListener('mouseenter', () => {
          bounds = $card.getBoundingClientRect();
          document.addEventListener('mousemove', rotateToMouse);
        });
        
        $card.addEventListener('mouseleave', () => {
          document.removeEventListener('mousemove', rotateToMouse);
          $card.style.transform = '';
          $card.style.background = '';
        });        
    }    
    render() {
        
        return (
            <div className="container-fluid px-0">
                <div></div>
                <div data-slide="1" className="container px-0">
                    <div className="row mx-0">
                        <div className="col-md-6 pt-9 px-lg-0 pr-lg-4">
                            <TitleSubtitleDescription
                                heading="RTMS"
                                subHeading="A Satellite Interception System"
                                description="
                                    We recognise the critical importance of intercepting communication going on over Satellite for Law enforcement purposes. 
                                    <br><br>                            
                                    The system proposed here is designed to provide an end-to-end solution for lawful organizations to intercept and analyze the data retrieved via a user friendly software system. The traditional mobile communication and other means of land communications networks are easy to monitor; that is the reason that most threats use satellite mobiles for their coordination and planning. The main reason is that along with a wide coverage area, it is difficult to monitor or intercept. 
                                    <br><br>                            
                                    This secure and highly available network even in deserts, dens and highly remote areas where there is very difficult access of security agencies, makes it very favourite for anti-state forces. This reliable communication in top of hills, jungles, deserts keeps them connected with their operators and often prove dangerous and challenging for law enforcement agencies to respond to their offensive activities in well-prepared manners.                            
                                "
                                />
                        </div>
                        <div className="col-md-6 px-0 pt-lg-4 align-self-center scale-lg-2x ">
                            <div className="ThreeD__card position-relative">
                                <img 
                                    data-aos="fade-left"
                                    data-aos-delay="300"
                                    data-aos-duration="500"
                                    src={satellite} alt="" className="w-100" />
                                <div className="glow position-absolute w-100 top-0 h-100"></div>
                            </div>                            
                        </div>
                    </div>                
                </div>
            </div>
        )
    }
}
