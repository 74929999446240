import React, { Component } from 'react'
import thurayaNetwork from '../assets/img/satellite-monitoring-system-capabilities/thuraya-network.svg'
import analysisSystem from '../assets/img/satellite-monitoring-system-capabilities/analysis-system.svg'
import audioReplay from '../assets/img/satellite-monitoring-system-capabilities/audio-replay.svg'
import dataTransmitted from '../assets/img/satellite-monitoring-system-capabilities/data-transmitted.svg'
import faxTransmission from '../assets/img/satellite-monitoring-system-capabilities/fax-transmission.svg'
import smsInterception from '../assets/img/satellite-monitoring-system-capabilities/sms-interception.svg'


export default class RtmsCapabilities extends Component {
    state = {
        list: [
            { delay: "100", width: "42px", icon: thurayaNetwork, title: "Voice Telephony transmitted on the Thuraya Network"},
            { delay: "200", width: "55px", icon: smsInterception, title: "All SMS type interception (short message service) messages"},
            { delay: "300", width: "55px", icon: faxTransmission, title: "Fax Transmissions over the Thuraya Network"},
            { delay: "400", width: "62px", icon: dataTransmitted, title: "Data transmitted on the Thuraya Network"},
            { delay: "500", width: "58px", icon: audioReplay, title: "Audio Replay facilities to operator/transcriber’s terminals"},
            { delay: "600", width: "55px", icon: analysisSystem, title: "Data Availability for input to other analysis systems"},
        ]
    }
    render() {
        return (

            <div data-slide="3" className="container px-0 pt-10">
                <div className="text-center px-3">
                    <h6 className="text-cyan">OUR PROCESS</h6>
                    <h2 className="playfair-display-bold text-white lh-150 text-lg-h1">The Capabilities of  Satellite Monitoring System</h2>
                </div>
                <div className="row mx-0 py-5 px-4 px-lg-0 justify-content-center">
                    {this.state && this.state.list.map((item, i) => (
                        <div
                            data-aos={i%2 == 0 ? "fade-right" : "fade-left"}
                            data-aos-anchor-placement="bottom-bottom"
                            data-aos-delay={item.delay}
                            data-aos-duration="700" 
                            className="col-md-5 mb-4 px-2">
                            <div className="border-cyan-2 d-flex align-items-center px-4 py-4-2x h-100">
                                <img src={item.icon} width={item.width} alt="" />
                                <p className="text-secondary pl-4 mb-0">{item.title}</p>
                            </div>
                        </div>
                    ))}                    
                </div>
            </div>            
        )
    }
}
