import React, { Component } from 'react'
import createImage from '../assets/img/manage-social-media-accounts/create-image.svg'
import controlFollowers from '../assets/img/manage-social-media-accounts/control-followers.svg'
import editPost from '../assets/img/manage-social-media-accounts/edit-post.svg'
import saveComments from '../assets/img/manage-social-media-accounts/save-comments.svg'
import addSocial from '../assets/img/manage-social-media-accounts/add-social.svg'
import controlActivities from '../assets/img/manage-social-media-accounts/control-activities.svg'



export default class SocialMediaAccountControl extends Component {
    render() {
        return (
            <div data-slide="3" className="container px-0 pt-10">
                <div className="text-center px-3">
                    <h6 className="text-cyan">SOCIAL CONTROL</h6>
                    <h1 className="playfair-display-bold text-white text-lg-h1">Manage Your Social Media Accounts</h1>
                </div>
                <div className="row mx-0 py-5 px-4 px-lg-0 justify-content-center">
                    <div
                        data-aos="fade-right"
                        data-aos-anchor-placement="bottom-bottom"
                        data-aos-delay="100"
                        data-aos-duration="700"  
                        className="col-md-5 mb-4 px-2">
                        <div className="border-cyan-2 px-4 py-4 h-100">
                            <img src={createImage} width="50px" className="mb-3" alt="" />
                            <h5 className="text-white">CREATE IMAGE</h5>
                            <p className="text-secondary pl-0 mb-0">It’s up to you to manage your social media accounts. </p>
                        </div>
                    </div>
                    <div
                        data-aos="fade-left"
                        data-aos-anchor-placement="bottom-bottom"
                        data-aos-delay="200"
                        data-aos-duration="700" 
                        className="col-md-5 mb-4 px-2">
                        <div className="border-cyan-2 px-4 py-4 h-100">
                            <img src={controlFollowers} width="50px" className="mb-3" alt="" />
                            <h5 className="text-white">CONTROL FOLLOWERS</h5>
                            <p className="text-secondary pl-0 mb-0">It’s up to you to manage your social media accounts. </p>
                        </div>
                    </div>
                    <div
                        data-aos="fade-right"
                        data-aos-anchor-placement="bottom-bottom"
                        data-aos-delay="300"
                        data-aos-duration="700"  
                        className="col-md-5 mb-4 px-2">
                        <div className="border-cyan-2 px-4 py-4 h-100">
                            <img src={editPost} width="50px" className="mb-3" alt="" />
                            <h5 className="text-white">EDIT POST</h5>
                            <p className="text-secondary pl-0 mb-0">It’s up to you to manage your social media accounts. </p>
                        </div>
                    </div>
                    <div
                        data-aos="fade-left"
                        data-aos-anchor-placement="bottom-bottom"
                        data-aos-delay="200"
                        data-aos-duration="700"  
                        className="col-md-5 mb-4 px-2">
                        <div className="border-cyan-2 px-4 py-4 h-100">
                            <img src={controlActivities} width="50px" className="mb-3" alt="" />
                            <h5 className="text-white">CONTROL ACTIVITIES</h5>
                            <p className="text-secondary pl-0 mb-0">It’s up to you to manage your social media accounts. </p>
                        </div>
                    </div>
                    <div
                        data-aos="fade-right"
                        data-aos-anchor-placement="bottom-bottom"
                        data-aos-delay="300"
                        data-aos-duration="700"   
                        className="col-md-5 mb-4 px-2">
                        <div className="border-cyan-2 px-4 py-4 h-100">
                            <img src={addSocial} width="50px" className="mb-3" alt="" />
                            <h5 className="text-white">ADD SOCIAL</h5>
                            <p className="text-secondary pl-0 mb-0">It’s up to you to manage your social media accounts. </p>
                        </div>
                    </div>
                    <div
                        data-aos="fade-left"
                        data-aos-anchor-placement="bottom-bottom"
                        data-aos-delay="200"
                        data-aos-duration="700"  
                        className="col-md-5 mb-4 px-2">
                        <div className="border-cyan-2 px-4 py-4 h-100">
                            <img src={saveComments} width="50px" className="mb-3" alt="" />
                            <h5 className="text-white">SAVE YOUR COMMENTS</h5>
                            <p className="text-secondary pl-0 mb-0">It’s up to you to manage your social media accounts. </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
