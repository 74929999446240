import React, { Component } from 'react'

import dots from '../../assets/img/dots2.svg'
import bg from '../../assets/img/capibilities/bg.png'

export default class GifCardDesign extends Component {
    render() {
        let { value, img, sub, title, desc, key } = this.props
        return (
            <div
                key={key}
                data-title={value}
                className="row mx-0 py-5 my-0 my-lg-5 px-4 px-lg-0">
                <div className="col-lg-5 position-relative px-0">
                    <div className="position-relative h-100">
                        <div className="position-relative z-1000 h-100 d-flex align-items-center justify-content-center overflow-hidden">
                            <img 
                            // data-aos-anchor-placement="top-center"
                                data-aos="zoom-out"
                                data-aos-duration="500" 
                                style={{ transform: "scale(1.75)"}}
                                src={img} className="w-100" alt="" />
                        </div>
                        <div className="position-absolute w-100 h-100 row mx-0 top-0 d-none d-lg-flex">
                            <div className="col-lg-5 px-0 align-self-end" style={{ transform: `translate(-25%, 25%)`}}>
                                <img
                                    src={dots} className="w-100"alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-7 px-0">
                    <div
                        data-aos="fade-left"
                        data-aos-delay="500"
                        data-aos-duration="500" 
                         className="w-100 h-100 text-white px-lg-5 px-3 py-7 position-relative z-2000 bg-overlay-3">
                        <div 
                            className="border-cyan border-w2 h-100 w-100 text-center position-relative">
                            <div className="px-5 position-absolute top-0 w-100" style={{ transform: `translateY(-30%)`}}>
                                <p  
                                    className="text-uppercase text-cyan mx-lg-4 bg-overlay-4">{sub}</p>
                            </div>
                            <div className="w-100 h-100 row mx-0">
                                <div className="col-lg-12 align-self-center px-lg-5 px-3 py-5">
                                    <h1 
                                        className="d-none d-lg-block playfair-display-bold">{title}</h1>
                                    <h1 className="d-block d-lg-none playfair-display-bold">{title}</h1>
                                    <p 
                                        dangerouslySetInnerHTML={{ __html: desc }}
                                        className="medium pt-4 text-white lh-150 text-capitalize"></p>                          
                                </div>
                            </div>
                        </div>  
                    </div>
                    <div className="position-absolute w-100 h-100 row mx-0 top-0 d-flex d-lg-none">
                        <div className="col-3 px-0 align-self-end" style={{ transform: `translate(-25%, 25%)`}}>
                            <img 
                                data-aos="zoom-out"
                                data-aos-delay="1000"
                                data-aos-duration="500" 
                                src={dots} className="w-100" alt="" />
                        </div>
                    </div>                                              

                </div>
            </div>
        )
    }
}
