import React, { Component } from 'react'

import imageAnalytics from '../assets/img/rapideye-unique-proposition/image-analytics.png'
import videoTranscription from '../assets/img/rapideye-unique-proposition/video-transcription.png'
import audioTranscription from '../assets/img/rapideye-unique-proposition/audio-transcription.png'
import speakerTranscription from '../assets/img/rapideye-unique-proposition/speaker-transcription.png'

import imageAnalyticsSm from '../assets/img/rapideye-unique-proposition/image-analytics-sm.png'
import videoTranscriptionSm from '../assets/img/rapideye-unique-proposition/video-transcription-sm.png'
import audioTranscriptionSm from '../assets/img/rapideye-unique-proposition/audio-transcription-sm.png'
import speakerTranscriptionSm from '../assets/img/rapideye-unique-proposition/speaker-transcription-sm.png'
import '../assets/css/carousel.css'
import divider from '../assets/img/divider-lg.svg'

import Swiper, { Navigation, Pagination } from 'swiper'
Swiper.use([Navigation, Pagination])

export default class UniquePropositionRapideye extends Component {
    state = {
        list: [
            { title: "IMAGE ANALYTICS" , description: "FRS, Image Forensic", bg: imageAnalytics, bgSm: imageAnalyticsSm },
            { title: "VIDEO TRANSCRIPTION" , description: "Video To Text, Categorization, Sentiment Analysis, SRS", bg: videoTranscription, bgSm: videoTranscriptionSm },
            { title: "AUDIO TRANSCRIPTION" , description: "audio to text, multiple languauge detection, sentiment analysis, categorization", bg: audioTranscription, bgSm: audioTranscriptionSm },
            { title: "SPEAKER RECOGNITION" , description: "Voice Matching, Background Noise Detection, Multiple Voice Detection, Audio To Text", bg: speakerTranscription, bgSm: speakerTranscriptionSm },
        ]
    }
    componentDidMount() {
        new Swiper(".swiper-container", {
            slidesPerView: 1,
            spaceBetween: 10,
            centeredSlides: true,
            freeMode: false,
            grabCursor: false,
            loop: true,
            pagination: {
            el: ".swiper-pagination",
            clickable: true
            },
            autoplay: {
            delay: 4000,
            disableOnInteraction: false
            },
            navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
            },
            breakpoints: {
            500: {
                slidesPerView: 1
            },
            700: {
                slidesPerView: 1
            }
            }
        })        
    }
    render() {
        return (
            <div data-slide="4" className="container px-0 py-10">
                <div className="text-center py-5">
                    <h6 className="text-cyan">UNIQUE PROPOSITIONS</h6>
                    <h2 className="text-lg-h1 lh-150 playfair-display-bold text-white">Topping intelligence through talent</h2>
                </div>
                <main className="main">
                        <div className="swiper swiper-container">
                            <div className="swiper-wrapper position-relative">
                                {this.state && this.state.list.map((item, i) => (
                                    <div className="swiper-slide">
                                        <div className="card-image">
                                            <div className="row mx-0 position-absolute dark-linear-gradient w-100 h-100 top-0 left-0 justify-content-end" >
                                                <div className="col-lg-3 col-8 text-white align-self-center px-0 position-relative text-left mt-5 mt-lg-0">
                                                    <h2 className="d-none d-lg-block text-cyan mb-1">{item.title}</h2>
                                                    <h5 className="d-block d-lg-none text-cyan mb-1">{item.title}</h5>
                                                    <div className="w-100 mb-lg-3 mb-2 pr-5 pr-lg-0 mt-2 mt-lg-0"><img src={divider} width="95%" alt="" /></div>
                                                    <p className="d-none d-lg-block text-capitalize">{item.description}</p>
                                                    <p className="d-block d-lg-none text-capitalize small pr-2 pr-lg-0">{item.description}</p>
                                                </div>
                                                <div className="col-lg-2"></div>
                                            </div>
                                            <img src={item.bg} className="d-none d-lg-block" alt="Image Slider" />
                                            <img src={item.bgSm} className="d-block d-lg-none" alt="Image Slider" />
                                        </div>
                                    </div>
                                ))}
                                
                                
                               
                            </div>
                            {/* <div className="swiper-pagination position-relative mt-4"></div> */}
                            <div className="row mx-0 w-100 h-100 position-absolute top-0 left-0 z-2000 justify-content-end">
                                <div className="col-lg-2 text-center align-self-end py-5 mb-5 mb-lg-0">
                                    <div className="position-relative swiper-button-prev border-secondary btn-circle no-bg-img d-inline-block my-0 mr-4">
                                        <i className="mdi mdi-chevron-left mdi-24px text-white"></i>
                                    </div>
                                    <div className="position-relative swiper-button-next btn-circle no-bg-img d-inline-block my-0 ml-3 border-secondary">
                                        <i className="mdi mdi-chevron-right mdi-24px text-white"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                </main>                
            </div>
        )
    }
}
