import React, { Component } from 'react'

import img1 from '../assets/img/satellite-communication-interception/1.png'
import img2 from '../assets/img/satellite-communication-interception/2.png'
import img3 from '../assets/img/satellite-communication-interception/3.png'
import UnorderedList from './widgets/UnorderedList'

export default class SatelliteCommunicationInterception extends Component {
    render() {
        return (

            <div data-slide="4" className="container px-0 py-lg-5 py-4 my-lg-5">
                <div className="text-center">
                    <h6 className="text-cyan">WHAT WE OFFER</h6>
                    <h1 className="playfair-display-bold text-white text-lg-h1">Satellite Communication Interception</h1>
                </div>
                <div className="row mx-0 py-5">
                    <div className="col-lg-6 px-0">
                        <div className="row mx-0">
                            <div className="col-lg-6 col-6 pr-lg-3 pr-2 pl-0">
                                <img
                                    data-aos="zoom-out"
                                    data-aos-delay="100"
                                    data-aos-duration="700" 
                                    src={img1} className="w-100 mb-lg-2 mb-2" alt="" />
                                <img
                                    data-aos="zoom-out"
                                    data-aos-delay="200"
                                    data-aos-duration="700"  
                                    src={img2} className="w-100 pt-lg-3 pt-1" alt="" />
                            </div>
                            <div className="col-lg-6 col-6 pl-1 align-self-center pr-0">
                                <img
                                    data-aos="zoom-out"
                                    data-aos-delay="500"
                                    data-aos-duration="700" 
                                    src={img3} className="w-100" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 pr-0 pl-5 mt-5 align-self-center">

                        <UnorderedList 
                            desc="Our Strategic Monitoring System has following components:"
                            checklist={
                                [
                                    "Details of the transmission e.g. the handset setting up a call, responding to an incoming call, updating its location or confirming its position.",
                                    "Calling and called numbers",
                                    "The IMSI ,TMSI, Power class and IMEI of the handset",
                                    "GPS position (latitude and longitude) and GPS timestamp",
                                    "Country/Region",
                                    "Current Thuraya Spot Beam ID, GPS position of its geographic centre and operating frequency",
                                    "Details of the ciphering algorithm and key used (A5/1/2/3/4/5/6/7)",
                                    "The voice, fax or data content in the call"
                                ]
                            }
                        />                        
                    </div>
                </div>
            </div>       
            
        )
    }
}
