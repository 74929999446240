import React, { Component } from 'react'
import facebook from '../assets/img/social/facebook.svg'
import linkedin from '../assets/img/social/linkedin.svg'
import twitter from '../assets/img/social/twitter.svg'
import instagram from '../assets/img/social/instagram.svg'
import { Link } from 'react-router-dom'
import divider from '../assets/img/divider.svg'
import logo from '../assets/img/logo/footer.png'
import AOS from 'aos';

export default class Footer extends Component {
    componentDidMount() {
        window.addEventListener('load', AOS.refresh)        
    }
    render() {
        return (
            <footer id="footer" className="container-fluid px-0">
                <div className="container pt-md-4 text-white">
                    <div className="row mx-0 pt-lg-5 pb-4 mb-md-0 justify-content-between">
                        <div className="col-md-2 px-3 px-md-0 mt-md-0 mt-5">
                            <h6 className="font-weight-bold ls-1">ABOUT</h6>
                            <div className="w-100"><img src={divider} width="48px" alt="" /></div>
                            <div className="mt-2 mt-md-2">
                                <Link to="/what-we-do" className="btn-block text-white text-left px-0 mb-3"><span className="small">What We Do</span></Link>
                                <Link to="/national-security" className="btn-block text-white text-left px-0 mb-3"><span className="small">How We Serve National Security</span></Link>
                                <Link to="/perception-management" className="btn-block text-white text-left px-0 mb-3"><span className="small">Perception Management</span></Link>
                                {/* <a href="https://www.linkedin.com/company/rapidev-recruitment/" target="_blank" className="btn-block text-white text-left px-0 mb-3"><span className="small">Careers</span></a> */}
                            </div>
                        </div>
                        <div className="col-md-2 px-3 px-md-0 mt-md-0 mt-5">
                            <h6 className="font-weight-bold ls-1">PRODUCTS</h6>
                            <div className="w-100"><img src={divider} width="48px" alt="" /></div>
                            <div className="mt-2 mt-md-2">
                                <Link to="/owlsense" className="btn-block text-white text-left px-0 mb-3"><span className="small">OwlSense AI</span></Link>
                                <Link to="/rapideye" className="btn-block text-white text-left px-0 mb-3"><span className="small">Rapid Fusion Centre</span></Link>
                                {/* <Link to="/thuraya-satellite" className="btn-block text-white text-left px-0 mb-3"><span className="small">Satellite Monitoring System</span></Link> */}
                                <Link to="/perception-management-tool" style={{ whiteSpace: "nowrap" }} className="btn-block text-white text-left px-0 mb-3"><span className="small">Perception Management Tool</span></Link>
                                <a href="javascript:void(0)" className="btn-block text-white text-left px-0 mb-3"><span className="small">AiIm</span></a>
                            </div>
                        </div>  
                        <div className="col-md-2 px-3 px-md-0 mt-md-0 mt-5">
                            <h6 className="font-weight-bold ls-1">CAPABILITIES</h6>
                            <div className="w-100"><img src={divider} width="48px" alt="" /></div>
                            <div className="mt-2 mt-md-2">
                                <Link to="/capabilities/threat_intelligence/terrorism_threat_investigation" className="btn-block text-white text-left px-0 mb-3"><span className="small">Threat Intelligence</span></Link>
                                <Link to="/capabilities/national_security_intelligence/law_order_maintainance" className="btn-block text-white text-left px-0 mb-3"><span className="small">National Security Intelligence</span></Link>
                                <Link to="/capabilities/perception_management_intelligence/narrative_influencers_analysis" className="btn-block text-white text-left px-0 mb-3"><span className="small">Perception Management Intelligence</span></Link>
                                <Link to="/perception-management-tool" className="btn-block text-white text-left px-0 mb-3"><span className="small">Perception Management Tool</span></Link>
                            </div>
                        </div>   
                        <div className="col-md-2 px-3 px-md-0 mt-md-0 mt-5">
                            <h6 className="font-weight-bold ls-1">CONTACT US</h6>
                            <div className="w-100"><img src={divider} width="48px" alt="" /></div>
                            <div className="mt-0 mt-md-2">
                                {/* <p className="text-cyan small mb-2 pt-3 pt-md-2">Marketing & Sales Department</p>
                                <p className="small mb-2">051-8849001-2 Ext: (110)</p> */}
                                <p className="small mb-4">marketing@theowlsense.com</p>
                                {/* <p className="text-cyan mb-2 small">Procurement Department</p>
                                <p className="small">051-8849001-2 Ext: (104)</p>
                                <p className="text-cyan small mb-2 pt-2 pt-md-2">Human Resource Department</p>
                                <p className="small">051-8849001-2 Ext: (105)</p> */}
                            </div>                            
                        </div> 
                        {/* <div className="col-md-2 px-lg-0 px-3 pt-lg-0 pt-5">
                            <h6 className="font-weight-bold ls-1">FOLLOW US</h6>
                            <div className="w-100"><img src={divider} width="48px" alt="" /></div>                            
                            <div className="px-0 my-3">
                                <a href="https://www.instagram.com/rapidev.pk" target="_blank" className="pr-4">
                                    <img src={instagram} width="22px" alt="" />
                                </a>
                                <a href="https://www.facebook.com/Rapidev.Official/" target="_blank" className="pr-4">
                                    <img src={facebook} width="22px" alt="" />
                                </a>
                                <a href="https://twitter.com/rapidevd" target="_blank" className="pr-4">
                                    <img src={twitter} width="22px" alt="" />
                                </a>
                                <a href="https://www.linkedin.com/company/rapidev/mycompany/" target="_blank" className="pr-4">
                                    <img src={linkedin} width="22px" alt="" />
                                </a>
                            </div>

                        </div>     */}
                        <div className="text-center col-md-12 pt-5 pb-8">
                            <p className="small py-4">Copyright ©OwlSense 2020. All rights reserved.</p>
                            <div className="row mx-0 justify-content-center">
                                <div className="col-lg-4 px-4">
                                    <img src={logo} className="w-100" alt="" />
                                </div>                                
                            </div>
                        </div>           
                    </div>
                </div>
            </footer>
        )
    }
}
