import React, { Component } from 'react'

export default class TitleSubtitleDescription extends Component {
    render() {
        let { heading, subHeading, description } = this.props
        return (
            <div className="pr-4">                
                <div className="pl-4">
                    <h1 
                        data-aos="fade-right"
                        data-aos-delay="500"
                        data-aos-duration="700"
                        className="playfair-display-bold text-white display-lg-4">{heading}</h1>
                    <h5 
                        data-aos="fade-right"
                        data-aos-delay="500"
                        data-aos-duration="700"
                        dangerouslySetInnerHTML={{ __html: subHeading }}
                        className="text-info-2 py-4"></h5>
                </div>
                <p             
                    data-aos="fade-up"
                    data-aos-delay="500"
                    data-aos-duration="700"
                    dangerouslySetInnerHTML={{ __html: description }}
                    className="border-left-info-2-sm text-light-blue-2 pl-4 pr-4 pr-lg-0"></p>
            </div>
        )
    }
}
