import React, { Component } from 'react'
import bg from '../assets/img/contact.png'

export default class ContactInfo extends Component {
    render() {
        return (
            <div data-slide="-1" id="hero-container" className="position-relative" style={{ background: `url(${bg})` }}>
                <div id="hero-body" className="flex-wrap align-items-end position-relative pt-lg-5 pb-lg-5 pb-0">
                    <div className="container text-white">
                        <div className="row mx-0 align-items-start justify-content-center mb-lg-5">
                            <div className="col-md-4 col-10 mb-lg-0 mb-4">
                                <h5 
                                    data-aos="zoom-out"
                                    data-aos-delay="300"
                                    data-aos-duration="700" 
                                    className="text-cyan ls-1 mb-3">ADDRESS</h5>
                                <p 
                                    data-aos="zoom-out"
                                    data-aos-delay="500"
                                    data-aos-duration="700"
                                    className="lh-175 ls-1">G204-206, NSTP NUST, <br /> Islamabad</p>
                            </div>
                            <div className="col-md-4 col-10 mb-lg-0 mb-4">
                                <h5 
                                    data-aos="zoom-out"
                                    data-aos-delay="300"
                                    data-aos-duration="700" 
                                    className="text-cyan ls-1 mb-3">REACH US AT</h5>
                                <p 
                                    data-aos="zoom-out"
                                    data-aos-delay="500"
                                    data-aos-duration="700"
                                    className="lh-175 ls-1">marketing@rapidev.ae <br /> 051-8849001-2 Ext: (110)</p>
                            </div>
                            <div className="col-md-4 col-10 mb-lg-0 mb-2">
                                <h5 
                                    data-aos="zoom-out"
                                    data-aos-delay="300"
                                    data-aos-duration="700" 
                                    className="text-cyan ls-1 mb-3">WORKING HOURS</h5>
                                <p 
                                    data-aos="zoom-out"
                                    data-aos-delay="500"
                                    data-aos-duration="700"
                                    className="lh-175 ls-1">Monday - Friday: 09:00 - 18:00</p>
                            </div>
                        </div>
                    </div>                     
                </div>
            </div>
        )
    }
}
