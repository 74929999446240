import React, { Component } from 'react'
import CapibilitiesTabContext from '../../context/CapibilitiesTabContext'
import $ from 'jquery'

export default class Tabs extends Component {
    state = {
        tabs: [
            { name: "Threat Intelligence", value: "threat_intelligence" },
            { name: "National Security Intelligence", value: "national_security_intelligence" },
            { name: "Perception Management Intelligence", value: "perception_management_intelligence" },
        ]
    }
    componentDidMount() {
        let { setTab } = this.context

        if (this.props.params) {
            let { tab, item } = this.props.params
            setTab({
                name: tab,
                value: tab
            })
            setTimeout(() => {
                let itemRef = $(`[data-title=${item}]`)
                
                if (itemRef && itemRef.offset()) {
                    $('html, body').animate({
                        scrollTop: itemRef.offset().top - $("#navbar").outerHeight() + 10
                    }, 699)                                        
                }
            }, 499)
        }
    }
    render() {
        const { tab, setTab } = this.context
        return (
            <div className="px-lg-4">
                <div className="row mx-0 mt-5 px-lg-5 px-3">
                    {this.state.tabs && this.state.tabs.map((tab_, i) => (
                        <div 
                            className={"col-4 px-0 border-w2 " + (
                                tab_.value == tab.value ? "border-bottom-cyan" : "border-bottom-cyan-opaque"
                            )}>
                            <a 
                                href="javascript:void(0)"
                                onClick={() => setTab(tab_)}
                                key={i}
                                className={"text-center btn-block py-4 border-radius-0 small text-lg-medium px-2 " + (
                                    tab_.value == tab.value ? "text-cyan" : "text-light-blue-2"
                                )}>{ tab_.name }</a>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}

Tabs.contextType = CapibilitiesTabContext