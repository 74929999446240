import React, { Component } from 'react'
import hero from '../assets/img/hero/owlsense-ai.png'
import logo from '../assets/img/logo/owlsense-ai.png'
import HeroHome from '../components/hero/HeroHome'
import WhatWeDo from '../components/WhatWeDo'
import NationalSecurity from '../components/NationalSecurity'
import PerceptionManagement from '../components/PerceptionManagement'
import OurProducts from '../components/OurProducts'
import OurCapabilities from '../components/OurCapabilities'
import ContactInfo from '../components/ContactInfo'
import TitleDescButton from '../components/text/TitleDescButton'
import horizontalLine from '../assets/img/horizontal-line.svg'
import Indicators from '../components/Indicators'
import IndicatorsContext, { IndicatorsProvider } from '../context/IndicatorsContext'
import $ from 'jquery'
import ContactInfo2 from '../components/ContactInfo2'

export default class Home extends Component {

    componentDidMount() {
        document.title = "Home | Owlsense"
        if (!this.props.match.params.section) {
            $('html, body').animate({
                scrollTop: 0
            }, 1000)    
        }
    }      
    render() {
        return (
            <div className="container-fluid px-0">
                <IndicatorsProvider>
                    
                    {/* Indicators showing... */}
                    <Indicators />

                    <HeroHome
                        bg={hero}
                        heading="<span class='pt-mono-regular'>CYBERWARFARE</span>"
                        description="OWLSENSE-AI"
                        logo={logo}
                        />
                    <WhatWeDo                         
                        params={this.props.match.params}/>
                        
                    <NationalSecurity 
                        params={this.props.match.params}/>
                    <PerceptionManagement
                        params={this.props.match.params} />
                    <OurProducts />
                    <OurCapabilities />
                    <ContactInfo                         
                        params={this.props.match.params}/>
                    {/* <TitleDescButton
                        heading="Want to know more?"
                        subHeading="Request a free demo to discover how <span class='font-weight-bold'>OwlSense (Pvt.) Ltd.</span> assures open-source/private intercepted data intelligence and delivers a validated, independent, impartial perspective on threats and investigations."
                    /> */}
                    {/* <div className="w-100 text-center pb-10 pt-5">
                        <img
                            data-aos="zoom-in"
                            data-aos-delay="1000"
                            data-aos-duration="1000"  
                            src={horizontalLine} width="3px" height="77px" alt="Horizontal Line" />
                    </div> */}
                </IndicatorsProvider>
            </div>
        )
    }
}
Home.contextType = IndicatorsContext