import React, { Component } from 'react'
import previewImg from '../assets/img/unique-proposition.png'

export default class UniqueProposition extends Component {
    render() {
        return (
            <div data-slide="4" className="row mx-0 pt-10">
                <div className="col-lg-3 px-0 pb-4">
                    <img src={previewImg} className="w-100" alt="" />
                </div>
                <div className="col-lg-8 text-white pl-lg-5 pl-4 pt-5">
                    <p 
                        data-aos="fade-up"
                        data-aos-delay="100"
                        data-aos-duration="700"
                        className="small mb-0 text-cyan">
                        <span className="ls-0 pr-3">────</span>
                        DRIVING YOUR WAY THROUGH INNOVATION
                    </p>   
                    <h2 
                        data-aos="fade-up"
                        data-aos-delay="300"
                        data-aos-duration="1000"
                        className="playfair-display-bold py-4 lh-150 display-lg-4">Our Unique Proposition</h2>     
                    <div className="row mx-0 py-4">
                        <div className="col-lg-5 px-0">
                            <p 
                                data-aos="zoom-out"
                                data-aos-delay="100"
                                data-aos-duration="1000"
                                className="small pt-2 pb-4 px-2 border-bottom-secondary">Integration with National Databases and other Intelligence Software</p>
                            <p 
                                data-aos="zoom-out"
                                data-aos-delay="200"
                                data-aos-duration="1000"
                                className="small pt-2 pb-4 px-2 border-bottom-secondary">100% Indigenous Crawlers</p>
                            <p 
                                data-aos="zoom-out"
                                data-aos-delay="300"
                                data-aos-duration="1000"
                                className="small pt-2 pb-4 px-2 border-bottom-secondary">Without any API limitations</p>
                            <p 
                                data-aos="zoom-out"
                                data-aos-delay="400"
                                data-aos-duration="1000"
                                className="small pt-2 pb-4 px-2 border-bottom-secondary">Dedicated AI Engine</p>
                            <p 
                                data-aos="zoom-out"
                                data-aos-delay="500"
                                data-aos-duration="1000"
                                className="small pt-2 pb-4 px-2 border-bottom-secondary">Powerful Raw Crawling</p>
                            <p 
                                data-aos="zoom-out"
                                data-aos-delay="600"
                                data-aos-duration="1000"
                                className="small pt-2 pb-4 px-2 border-bottom-secondary">Highly Scalable & Customizable</p>
                        </div>
                        <div className="col-lg-5 mx-auto align-self-center text-center">
                            <div className="row mx-0 pt-3 pb-4 align-items-center">
                                <h4 
                                    data-aos="fade-left"
                                    data-aos-delay="100"
                                    data-aos-duration="1000"
                                    className="text-lg-h2 text-cyan col-lg-4 col-4 text-left px-0 mb-0 montserrat-semibold pb-0 text-right px-4">100%</h4>
                                <p 
                                    data-aos="fade-left"
                                    data-aos-delay="300"
                                    data-aos-duration="1000"
                                    className="text-lg-medium small pb-0 mb-0 col-lg-8 col-8 text-left px-0 montserrat-regular">Client Satisfaction Guaranteed</p>
                            </div>
                            <div className="row mx-0 pt-3 pb-4 align-items-center">
                                <h4 
                                    data-aos="fade-left"
                                    data-aos-delay="100"
                                    data-aos-duration="1000"
                                    className="text-lg-h2 text-cyan col-lg-4 col-4 text-left px-0 mb-0 montserrat-semibold pb-0 text-right px-4">50+</h4>
                                <p 
                                    data-aos="fade-left"
                                    data-aos-delay="300"
                                    data-aos-duration="1000"
                                    className="text-lg-medium small pb-0 mb-0 col-lg-8 col-8 text-left px-0 montserrat-regular">Upto 50 categorizations</p>
                            </div>
                            <div className="row mx-0 pt-3 pb-4 align-items-center">
                                <h4 
                                    data-aos="fade-left"
                                    data-aos-delay="100"
                                    data-aos-duration="1000"
                                    className="text-lg-h2 text-cyan col-lg-4 col-4 text-left px-0 mb-0 montserrat-semibold pb-0 text-right px-4">150+</h4>
                                <p 
                                    data-aos="fade-left"
                                    data-aos-delay="300"
                                    data-aos-duration="1000"
                                    className="text-lg-medium small pb-0 mb-0 col-lg-8 col-8 text-left px-0 montserrat-regular">Upto 180 languages</p>
                            </div>
                        </div>
                    </div>            
                </div>
            </div>
        )
    }
}
