import React, { Component } from 'react'

import owlsenseAi from '../assets/img/our-products/owlsense-ai.png'
import perceptionTool from '../assets/img/our-products/perception-tool.png'
import rapideye from '../assets/img/our-products/rapideye.png'
import rapidThuraya from '../assets/img/our-products/rapid-thuraya.png'
import ailm from '../assets/img/our-products/ailm.png'
import dots from '../assets/img/dots2.svg'
import { Link } from 'react-router-dom'

export default class OurProducts extends Component {
    state = {
        products: [
            { img: owlsenseAi, sub: "AN OPEN SOURCE INTELLIGENCE <br> SYSTEM", href: "/owlsense", title: "OwlSense AI", desc: "OwlSense is an intelligence platform that is focused on the open web sources to collect, analyse, summarize and monitor massive public data and identify the key information from them, analyse it and notify it via reports."},
            { img: rapideye, sub: "AN INTELLIGENCE FUSION CENTRE", href: "/rapideye", title: "RapidEye", desc: "An effective and efficient Intelligence tool to gather, exchange & analyse information and intelligence. <br> <br> State-of-the-art intelligence fusion centre software customized as per requirements, to enhance local capabilities, capacity building, upgradation of existing infrastructure."},
            // { img: rapidThuraya, sub: "SATELLITE INTERCEPTION SOLUTION", title: "Satellite Monitoring System ", desc: "Satellite Monitoring System (RTMS) provides an end-to-end solution for intelligence organizations to intercept and analyse the data retrieved via a user friendly software system. It recognises the critical importance of intercepting communication going on over Satellite for Law Enforcement purposes."},
            { img: perceptionTool, sub: "A REPUTE MANAGEMENT SOLUTION", href: "/perception-management-tool", title: "Perception Management Tool", desc: "Our tools fetches data from all <i>Open Source platforms</i> that helps a brand track what people are saying about them or their specific products & services. Anything negative can be easily identified with the fetched data and can be managed under social media management. These analytics would help a brand maintain their brand image. <br> <br> But we are not limited to commercial brands only, this can be useful for Government Organizations, Public Figures, Electoral Campaigns, Influencers and many more."},
            // { img: ailm, sub: "AN AI POWERED MARKETING TOOL", title: "AiIM", desc: "Our Marketing Solution combines Social Media Marketing with AI-powered Analytics to ensure that your product reaches its rightful buyer."},
        ]
    }
    render() {
        return (
            <div data-slide="4" className="container px-0 pt-lg-5 pt-0">
                <div className="row mx-0 align-items-center text-white pt-0 pt-lg-0 pb-5">
                    <div className="col-lg-5 px-3 px-lg-0 pt-0 ">
                        <h1 
                            data-aos="fade-right"
                            data-aos-anchor-placement="bottom-bottom"
                            data-aos-delay="300"
                            data-aos-duration="700"
                            className="display-4 playfair-display-bold d-none d-lg-block">View our products</h1>
                            <h2 
                                data-aos="fade-right"
                                data-aos-anchor-placement="bottom-bottom"
                                data-aos-delay="300"
                                data-aos-duration="700"
                                className="d-flex align-items-center d-lg-none justify-content-start">
                                    <span className="playfair-display-bold flex-shrink-0 text-center px-2">View our products</span>
                                    <span
                                        data-aos="zoom-in"
                                        data-aos-anchor-placement="bottom-bottom"
                                        data-aos-delay="400"
                                        data-aos-duration="500" 
                                        style={{ width: "12px" }}
                                        className="text-info-2 align-self-center pr-3 ls-0 font-weight-bold">──</span>                                
                                </h2>
                    </div>
                    <div className="col-lg-6">
                        <p className="medium mb-0 d-flex pl-2 pt-lg-0 pt-2 pl-lg-4">
                            <span
                                data-aos="zoom-in"
                                data-aos-anchor-placement="bottom-bottom"
                                data-aos-delay="400"
                                data-aos-duration="500" 
                                className="text-info-2 align-self-center pr-3 ls-0 d-none d-lg-inline-block font-weight-bold">────</span>
                            <span
                                data-aos="zoom-in"
                                data-aos-anchor-placement="bottom-bottom"
                                data-aos-delay="600"
                                className="text-light-2"
                                data-aos-duration="600">
                                Here are  our featured products for you.
                                <br />
                                feel free to discover each of them
                            </span>
                        </p>
                    </div>
                </div>
                {this.state && this.state.products.map((product, i) => (
                    <div className="row mx-0 py-0 py-lg-5 my-lg-5 my-5">
                        <div className="col-lg-1"></div>
                        <div className="col-lg-4 col-12 position-relative pl-5 pr-4 px-lg-0">
                            <div className="position-relative px-lg-0 px-2">
                                <div className="position-relative z-1000">
                                    <img
                                        // data-aos-anchor-placement="top-center"
                                        data-aos="zoom-out"
                                        data-aos-delay="100"
                                        data-aos-duration="1000" 
                                        src={product.img} className="w-100" alt="" />
                                </div>
                                <div className="position-absolute w-100 h-100 row mx-0 top-0">
                                    <div className="col-lg-6 col-6 px-0 align-self-end" style={{ transform: `translate(-25%, 25%)`}}>
                                        <img
                                            data-aos-anchor-placement="bottom-bottom"
                                            data-aos="zoom-out"
                                            data-aos-delay="500"
                                            data-aos-duration="1000" 
                                            src={dots} className="w-100" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-0 h-100 position-absolute z-2000 top-0 w-100 left-0 px-lg-0 px-3 pb-5 pb-lg-0 translateX-lg--25 translateX-0">
                                <div className="col-lg-7 col-7 px-0 align-self-center mb-lg-3 mb-0 pb-lg-4 pb-0" >
                                    <div 
                                        data-aos-anchor-placement="bottom-bottom"
                                        data-aos="fade-down"
                                        data-aos-delay="800"
                                        data-aos-duration="1000"
                                        className="w-75 pb-2 border-top-info border-left-info border-w2"></div>
                                    <h6 
                                        data-aos-anchor-placement="bottom-bottom"
                                        data-aos="fade-right"
                                        data-aos-delay="800"
                                        data-aos-duration="1000"
                                        className="text-cyan montserrat-semibold mt-1">0{++i}.</h6>
                                    <h6 
                                        data-aos-anchor-placement="bottom-bottom"
                                        data-aos="fade-right"
                                        data-aos-delay="1000"
                                        data-aos-duration="700"
                                        dangerouslySetInnerHTML={{__html: product.sub}}
                                        style={{ zIndex: 999 }} className="font-weight-bold text-white ls-2 lh-175"></h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1"></div>
                        <div className="col-lg-5 text-white align-self-center px-4 px-lg-2 text-center text-lg-left">                
                            <h1 
                                data-aos-anchor-placement="bottom-bottom"
                                data-aos="fade-up"
                                data-aos-delay="300"
                                data-aos-duration="1000" 
                                className="display-4 d-none d-lg-block playfair-display-bold text-left">{product.title}</h1>
                            <h1 
                                data-aos-anchor-placement="bottom-bottom"
                                data-aos="fade-up"
                                data-aos-delay="300"
                                data-aos-duration="1000" 
                                className="d-block d-lg-none playfair-display-bold mt-5 pt-5 mb-0 text-left">{product.title}</h1>
                            <p 
                                data-aos-anchor-placement="bottom-bottom"
                                data-aos="fade-up"
                                data-aos-delay="500"
                                data-aos-duration="600" 
                                dangerouslySetInnerHTML={{ __html: product.desc }}
                                className="text-left medium pt-lg-4 pt-3 text-light-blue-2 pb-5"></p>
                            
                            <Link 
                                data-aos-anchor-placement="bottom-bottom"
                                data-aos="zoom-out"
                                data-aos-delay="500"
                                data-aos-duration="600" 
                                to={product.href} className="btn btn-info-2 px-5 py-3 ls-2 mb-5 mb-lg-0 shadow-cyan border-rounded position-relative">
                                <span className="small">SEE OUR PRODUCT</span>
                                <span 
                                    data-aos-anchor-placement="bottom-bottom" 
                                    data-aos="zoom-in"
                                    data-aos-delay="1000"
                                    data-aos-duration="1000"
                                    className="text-white align-self-center pr-3 position-absolute ls-0" style={{ transform: "translateX(100%)"}}>──</span>
                            </Link>
                        </div>
                    </div>

                ))}
            </div>
        )
    }
}
