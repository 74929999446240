import React, { Component } from 'react'
import '../../assets/css/orbit.css'

export default class Orbit extends Component {
    render() {
        return (
            <svg height="1050" width="1050" viewBox="0 0 1050 1050">
                <g class="group">
                    <circle class="one" cx="0" cy="0" r="147"></circle>
                    <circle class="two" cx="0" cy="0" r="107"></circle>
                    <circle class="three" cx="0" cy="0" r="61"></circle>
                    
                    <circle class="path four" cx="0" cy="0" r="221"></circle>
                    <circle class="four" cx="221" cy="0" r="15"></circle>
                    <circle class="four fill3" cx="221" cy="0" r="7"></circle>

                    <circle class="path five" cx="0" cy="0" r="314"></circle>
                    <circle class="five fill3" cx="314" cy="0" r="10"></circle>
                    <circle class="five speed-16s fill2" cx="314" cy="0" r="16"></circle>
                    <circle class="five speed-18s fill1" cx="314" cy="0" r="24"></circle>
                    
                    <circle class="path six" cx="0" cy="0" r="400"></circle>
                    <circle class="six" cx="400" cy="0" r="15"></circle>
                    <circle class="six speed-22s fill2" cx="400" cy="0" r="22"></circle>
                    <circle class="six speed-24s fill1" cx="400" cy="0" r="12"></circle>
                    <circle class="six speed-26s" cx="400" cy="0" r="30"></circle>
                    <circle class="six speed-26s fill3" cx="400" cy="0" r="15"></circle>
                </g>
            </svg>                
        )
    }
}
