import React, { Component } from 'react'
import bg from '../assets/img/perception-management/bg-2.png'
import narrativeAnalysis from '../assets/img/perception-management/narrative-analysis.svg'
import sentimentAnalysis from '../assets/img/perception-management/sentiment-analysis.svg'
import counterPropaganda from '../assets/img/perception-management/counter-propaganda.svg'
import influencersAnalysis from '../assets/img/perception-management/influencers-analysis.svg'
import managementAnalysis from '../assets/img/perception-management/management-analysis.svg'
import $ from 'jquery'

export default class PerceptionManagement extends Component {

    componentDidMount() {
        let { params } = this.props

        if (params.section == "perception-management") {
            $('html, body').animate({
                scrollTop: $("#perceptionmanagement").offset().top - $("#navbar").outerHeight() + 10
            }, 699)              
        }
    } 

    render() {
        return (
            <div data-slide="3" id="hero-container" className="container-fluid py-5 mb-5" style={{ background: `url(${bg})` }}>
                <div id="perceptionmanagement" className="container px-4 px-lg-0 py-lg-5 py-0">
                    <div className="row mx-0 py-lg-5 py-0">
                        <div className="col-lg-5 px-0 align-self-center">
                            <h1 
                                data-aos="fade-right"
                                data-aos-anchor-placement="top-bottom"
                                data-aos-delay="400"
                                data-aos-duration="700"
                                className="text-white playfair-display-bold lh-140 display-4 d-none d-lg-block">
                                Our <br /> 
                                <span className="playfair-display-bold text-info-2">
                                    Perception <br />
                                    Management
                                </span>
                            </h1>
                            <h1 
                                data-aos="fade-right"
                                data-aos-anchor-placement="top-bottom"
                                data-aos-delay="400"
                                data-aos-duration="700"
                                className="text-white petrona-bold d-block d-lg-none">
                                Our <br /> 
                                <span className="petrona-bold text-info-2">
                                    Perception <br />
                                    Management
                                </span>
                            </h1>                            
                        </div>
                        <div className="col-lg-7 px-0 text-white">
                            <h5 
                                data-aos="zoom-in"
                                data-aos-anchor-placement="top-bottom"
                                data-aos-delay="200"
                                data-aos-duration="700"
                                className="text-uppercase font-weight-bold mt-3 ls-2 mt-lg-0 lh-150">Manage your best image in the world of information manipulation!</h5>
                            <p 
                                data-aos="fade-up"
                                data-aos-anchor-placement="top-bottom"
                                data-aos-delay="200"
                                data-aos-duration="700"
                                className="medium lh-175 mt-3">
                                Within the age of <i>Information Wars and Behavioural  Control</i> through media impact, the most important fight is the one that lies inside the minds of the individuals. Perception Management is important whether it's for a country, an election campaign, new policies by the government, launch of a brand or a commercial company. We help organization manage their reputation by                                        
                            </p>
                            <div className="row mx-0 w-100 py-5 text-center justify-content-lg-between justify-content-center px-0 position-relative">
                                <div 
                                    data-aos-anchor-placement="bottom-bottom"
                                    data-aos="fade-in"
                                    data-aos-delay="1000"
                                    data-aos-duration="1000" 
                                    className="position-absolute d-none d-lg-block" style={{ top: "35%", width: "90%", left: "5%", borderTop: "1px dashed white"}}></div>
                                <div className="col-lg-2 col-6 px-0">
                                    <div
                                        data-aos-anchor-placement="bottom-bottom"
                                        data-aos="zoom-out"
                                        data-aos-delay="100"
                                        data-aos-duration="1000" 
                                        className="px-3 px-lg-0"><img src={narrativeAnalysis} className="w-100" alt="" /></div>
                                    <p 
                                        data-aos-anchor-placement="bottom-bottom"
                                        data-aos="fade-up"
                                        data-aos-delay="100"
                                        data-aos-duration="1000"
                                        className="small text-white">Narrative Analysis</p>
                                </div>
                                <div className="col-lg-2 col-6 px-0">
                                    <div
                                        data-aos-anchor-placement="bottom-bottom"
                                        data-aos="zoom-out"
                                        data-aos-delay="300"
                                        data-aos-duration="1000"  
                                        className="px-3 px-lg-0"><img src={sentimentAnalysis} className="w-100" alt="" /></div>
                                    <p 
                                        data-aos-anchor-placement="bottom-bottom"
                                        data-aos="fade-up"
                                        data-aos-delay="300"
                                        data-aos-duration="1000"
                                        className="small text-white">Sentiment Analysis</p>
                                </div>
                                <div className="col-lg-2 col-6 px-0">
                                    <div
                                        data-aos-anchor-placement="bottom-bottom"
                                        data-aos="zoom-out"
                                        data-aos-delay="500"
                                        data-aos-duration="1000"  
                                        className="px-3 px-lg-0"><img src={counterPropaganda} className="w-100" alt="" /></div>
                                    <p 
                                        data-aos-anchor-placement="bottom-bottom"
                                        data-aos="fade-up"
                                        data-aos-delay="500"
                                        data-aos-duration="1000"
                                        className="small text-white">Counter Propaganda</p>
                                </div>
                                <div className="col-lg-2 col-6 px-0">
                                    <div
                                        data-aos-anchor-placement="bottom-bottom"
                                        data-aos="zoom-out"
                                        data-aos-delay="700"
                                        data-aos-duration="1000"   
                                        className="px-3 px-lg-0"><img src={influencersAnalysis} className="w-100" alt="" /></div>
                                    <p 
                                        data-aos-anchor-placement="bottom-bottom"
                                        data-aos="fade-up"
                                        data-aos-delay="700"
                                        data-aos-duration="1000"
                                        className="small text-white">Influencers Analysis</p>
                                </div>
                                <div className="col-lg-2 col-6 px-0">
                                    <div
                                        data-aos-anchor-placement="bottom-bottom"
                                        data-aos="zoom-out"
                                        data-aos-delay="900"
                                        data-aos-duration="1000"  
                                        className="px-3 px-lg-0"><img src={managementAnalysis} className="w-100" alt="" /></div>
                                    <p 
                                        data-aos-anchor-placement="bottom-bottom"
                                        data-aos="fade-up"
                                        data-aos-delay="900"
                                        data-aos-duration="1000"
                                        className="small text-white">Social Media Management And Analysis</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}
