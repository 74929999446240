import React, { Component } from 'react'

import img1 from '../assets/img/intelligence-action/1.png'
import img2 from '../assets/img/intelligence-action/2.png'
import img3 from '../assets/img/intelligence-action/3.png'
import UnorderedList from './widgets/UnorderedList'

export default class IntelligenceAction extends Component {
    render() {
        return (
            <div data-slide="5" className="container px-0 py-7">
                <div className="text-center">
                    <h6 className="text-cyan">WHAT WE OFFER</h6>
                    <h2 className="playfair-display-bold text-white text-lg-h1">We are Intelligence in action.</h2>
                </div>
                <div className="row mx-0 py-5">
                    <div className="col-lg-6 px-0">
                        <div className="row mx-0">
                            <div className="col-lg-6 col-6 pr-lg-3 pr-2 pl-0">
                                <img
                                    data-aos="zoom-out"
                                    data-aos-delay="100"
                                    data-aos-duration="700" 
                                    src={img1} className="w-100 mb-lg-2 mb-2" alt="" />
                                <img
                                    data-aos="zoom-out"
                                    data-aos-delay="200"
                                    data-aos-duration="700" 
                                    src={img2} className="w-100 pt-lg-3 pt-1" alt="" />
                            </div>
                            <div className="col-lg-6 col-6 pl-1 align-self-center pr-0">
                                <img 
                                    data-aos="zoom-out"
                                    data-aos-delay="500"
                                    data-aos-duration="700"
                                    src={img3} className="w-100" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 pr-0 pl-lg-5 pl-4 pt-lg-0 pt-5">
                        <UnorderedList 
                            desc="The system is designed for intelligence professionals, emphasizing real world use cases and operational workflows. Following are the key features that we present, including both essential and expert packages."
                            checklist={
                                [
                                    "Identification & Countering Astroturfing",
                                    "Deep Analytical Insights",
                                    "Target Profiling",
                                    "Powerful Data Crawling and Integration",
                                    "Sentiment Analysis, Behaviour Analysis and Emotion Prediction",
                                    "Top Trends Extraction, Text Analytics",
                                    "Image Reverse-Lookup, Image Analytics",
                                    "Virtual Avatar Management",
                                    "Link Analysis and Automatic Reporting",
                                    "Private Social Media Account Crawling",
                                    "Identify Trolls against any State Agenda",
                                    "Identification of Sock Puppets",
                                ]
                            }
                        />      
                    </div>
                </div>
            </div>
        )
    }
}
