import React, { Component } from 'react'
import cuttingEdgeSolution from '../assets/img/what-is-owlsense/cutting-edge-solutions.png'
import aiMonitoringSystem from '../assets/img/what-is-owlsense/ai-monitoring-system.png'
import automaticAnalysisAndProcessing from '../assets/img/what-is-owlsense/automatic-analysis-and-processing.png'
import onlineIntelligence from '../assets/img/what-is-owlsense/online-intelligence.png'
import endToEndSolution from '../assets/img/what-is-owlsense/end-to-end-solution.png'


export default class WhatisOwlsense extends Component {
    render() {
        return (
            <div data-slide="2" className="container px-0 pt-10">
                <div className="text-center px-3">
                    <h6 className="text-cyan">OUR VALUE</h6>
                    <h2 className="playfair-display-bold text-white lh-150 text-lg-h1">The power of applied intelligence.</h2>
                </div>
                <div className="row mx-0 py-5 px-4 px-lg-0 justify-content-center">
                    <div
                        data-aos="fade-right"
                        data-aos-anchor-placement="bottom-bottom"
                        data-aos-delay="100"
                        data-aos-duration="700"  
                        className="col-md-5 mb-4 px-2">
                        <div className="border-cyan-2 d-flex align-items-center px-4 py-4 h-100">
                            <img src={cuttingEdgeSolution} width="50px" alt="" />
                            <p className="text-light-blue-2 pl-4 mb-0">Cutting-edge OSINT Solution</p>
                        </div>
                    </div>
                    <div
                        data-aos="fade-left"
                        data-aos-anchor-placement="bottom-bottom"
                        data-aos-delay="200"
                        data-aos-duration="700" 
                        className="col-md-5 mb-4 px-2">
                        <div className="border-cyan-2 d-flex align-items-center px-4 py-4 h-100">
                            <img src={aiMonitoringSystem} width="50px" alt="" />
                            <p className="text-light-blue-2 pl-4 mb-0">An All in one intelligence monitoring System</p>
                        </div>
                    </div>
                    <div
                        data-aos="fade-right"
                        data-aos-anchor-placement="bottom-bottom"
                        data-aos-delay="300"
                        data-aos-duration="700" 
                        className="col-md-5 mb-4 px-2">
                        <div className="border-cyan-2 d-flex align-items-center px-4 py-4 h-100">
                            <img src={automaticAnalysisAndProcessing} width="50px" alt="" />
                            <p className="text-light-blue-2 pl-4 mb-0">Turns the retrieved information into actionable intelligence by automatic analysis and processing</p>
                        </div>
                    </div>
                    <div
                        data-aos="fade-left"
                        data-aos-anchor-placement="bottom-bottom"
                        data-aos-delay="400"
                        data-aos-duration="700" 
                        className="col-md-5 mb-4 px-2">
                        <div className="border-cyan-2 d-flex align-items-center px-4 py-4 h-100">
                            <img src={onlineIntelligence} width="50px" alt="" />
                            <p className="text-light-blue-2 pl-4 mb-0">Uncovers online intelligence quickly & efficiently</p>
                        </div>
                    </div>
                    <div
                        data-aos="fade-up"
                        data-aos-anchor-placement="bottom-bottom"
                        data-aos-delay="500"
                        data-aos-duration="700" 
                        className="col-md-5 mb-4 px-2">
                        <div className="border-cyan-2 d-flex align-items-center px-4 py-4 h-100">
                            <img src={endToEndSolution} width="50px" alt="" />
                            <p className="text-light-blue-2 pl-4 mb-0">An end-to-end solution for lawful organizations</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
