import './App.css';
import './assets/css/font.css'
import './assets/css/background.css'
import './assets/css/text.css'
import './assets/css/height.css'
import './assets/css/padding.css'
import './assets/css/width.css'
import './assets/css/hover.css'
import './assets/css/border.css'
import './assets/css/animation.css'
import './assets/css/transform.css'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import Navbar from './layout/Navbar';
import Owlsense from './routes/Owlsense';
import bg from './assets/img/bg/lines.png'
import Rapideye from './routes/Rapideye';
import Home from './routes/Home';
import Footer from './layout/Footer';
import ThurayaSatellite from './routes/ThurayaSatellite';
import Capabilities from './routes/Capabilities';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles 
import PerceptionManagementTool from './routes/PerceptionManagementTool';
import './assets/css/lg-queries.css'

const history = createBrowserHistory()

function App() {
    AOS.init();
    // AOS.init({once: true});
    return (
        <Router history={history}>
            <div className="bg-main hero-container" style={{ background: `url(${bg}) 100% fixed` }}>
                <Navbar />
                <Switch>
                    <Route exact path="/owlsense">
                        <Owlsense />
                    </Route>
                    <Route exact path="/rapideye">
                        <Rapideye />
                    </Route>
                    <Route exact path="/thuraya-satellite">
                        <ThurayaSatellite />
                    </Route>
                    <Route exact path="/perception-management-tool">
                        <PerceptionManagementTool />
                    </Route>
                    <Route exact path="/:section?" render={(props) => 
                        (
                            <Home
                                key={props.match.params.section} 
                                {...props} />
                        )}>
                    </Route>                    
                    <Route exact path="/capabilities/:tab?/:item?" render={(props) => 
                        (<Capabilities 
                            key={props.match.params.item} 
                            {...props} />
                        )}
                    ></Route>
                </Switch>
                <Footer />
            </div>
        </Router>        
    );
}

export default App
