import React, { Component } from 'react'
import TitleSubtitleDescription from '../text/TitleSubtitleDescription'
import perceptionManagementTool from '../../assets/img/perception-management-tool.png'

export default class PerceptionManagementTool extends Component {
    render() {
        return (
            <div data-slide="1" className="container px-0">
                <div className="row mx-0">
                    <div className="col-md-6 pt-9 px-lg-0">
                        <TitleSubtitleDescription
                            heading="Reputation Management"
                            subHeading="A Marketing Solution"
                            description="
                                Our tools fetches data from all Open Source platforms that helps a brand track what people are saying about them or their specific products & services. Anything negative can be easily identified with the fetched data and can be managed under social media management. These analytics would help a brand maintain their brand image. 
                                <br><br>                                                            
                                But we are not limited to commercial brands only, this can be useful for Government Organizations, Public Figures, Electoral Campaigns, Influencers and many more.
                            "
                            />
                    </div>
                    <div className="col-md-6 pr-0 pt-4 align-self-center">
                        <img 
                            data-aos="zoom-out"
                            data-aos-delay="1000"
                            data-aos-duration="700"
                            src={perceptionManagementTool} alt="" className="w-100" />
                    </div>
                </div>                
            </div>
        )
    }
}
