import React, { Component } from 'react'
import '../../assets/css/video.css'
import $ from 'jquery'

export default class videoPreview extends Component {
    componentDidMount() {
        $(document).ready(function() {    
            // When the close button is clicked make the lightbox fade out in the span of 0.5 seconds and show the play button
            $("#lightbox").on("click", function(e) {
                if ($(e.target).prop("tagName") != "VIDEO") {
                    $("#lightbox").fadeOut(500)
                    $("#button").show(250)
                    $('html, body').css({
                        overflow: 'auto'
                    })
                    $("#intro-video").trigger('pause')      
                }
            })
        })        

    }
    render() {
        let { video } = this.props
        return (
            <div id="lightbox">
                <div id="video-wrapper" className="row mx-0 w-100 justify-content-center">
                    <div className="col-lg-9 col-11">
                        <video id="intro-video" src={video} className="w-100" controls></video>                
                    </div>                            
                </div>
            </div>    
        )
    }
}
