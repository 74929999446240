import React, { Component } from 'react'
import CapibilitiesTabContext from '../../context/CapibilitiesTabContext'

import cdInterception from '../../assets/img/gifs/noncopyright/CDR Interception.gif'
import threatDetection from '../../assets/img/gifs/noncopyright/External Threat Detection.gif'
import findingPeople from '../../assets/img/gifs/noncopyright/Kidnapping & Finding Missing People.gif'
import lawAndOrder from '../../assets/img/gifs/noncopyright/law n order.gif'
import satelliteInterceptionSystem from '../../assets/img/gifs/noncopyright/Satellite Monitoring & Interception System.gif'

import dots from '../../assets/img/dots2.svg'
import bg from '../../assets/img/capibilities/bg.png'
import GifCardDesign from '../widgets/GifCardDesign'

export default class NationalSecurityIntelligence extends Component {
    state = {
        capibilities: [
            { img: lawAndOrder, sub: "Protected at Every Side!", value: "law_order_maintainance", title: "Law and Order Maintainence", desc: "Central Fusion Center, National Database Monitoring, Safe City Data Integration, Thuraya Satellite Monitoring and CDR interception, Social Media Monitoring & Analysis, Speaker Recognition & Audio Transcription, FRS & Image Forensic, Video Transcription, Text Analytics."},
            // { img: satelliteInterceptionSystem, sub: "intercepting data from land to space!", value: "satellite_monitoring_system", title: "Satellite Monitoring System", desc: "Monitor & record the calls, SMS, FAX, GPS locations, graphical display of target location, sophisticated digital signal processing, monitor all headset uplink signals for whole satellite coverage, Speaker Recognition and Voice Transcription."},
            { img: cdInterception, sub: "we are always listening!", value: "cdr_interception", title: "CDR Interception ",  desc: "Monitor and intercept calls, Speaker Recognition and Voice Transcription, find out who called who, find the meaning of what's being said, location tracking."},
            { img: findingPeople, sub: "Redefining Security Needs!", value: "kidnapping_missing_persons_investigation", title: "Kidnapping and Finding Missing People ", desc: "Facial Recognition, Image Forensic, Criminal Database, Location Tracking, Call Interception, Data Link Analysis, National Databases."},
            { img: threatDetection, sub: "eyes everywhere!", value: "external_threats_detection", title: "External Threat Detection", desc: "Monitor Dark Web, find out information leaks, online propaganda analysis, uncover the source and propagators, fusion centre, sensitive news alerts."},
            { img: lawAndOrder, sub: "Redefining Security Needs!", value: "national_database_fusion_centre", title: "National Database Fusion Centre ", desc: "Facial Recognition, Image Forensic, Criminal Database, Location Tracking, Call Interception, Data Link Analysis, National Databases."},
        ]
    }
    render() {
        const { tab } = this.context
        return (
            tab.value == "national_security_intelligence" ?
                <div> 
                    {this.state && this.state.capibilities.map((capibility, i) => (
                        <GifCardDesign 
                            key={i}
                            img={capibility.img}
                            sub={capibility.sub}
                            value={capibility.value}
                            title={capibility.title}
                            desc={capibility.desc}
                        />

                    ))}
                </div>
            : null
        )
    }
}
NationalSecurityIntelligence.contextType = CapibilitiesTabContext
