import React, { Component } from 'react'
import completeIntelligenceControl from '../assets/img/complete-intelligence-control.svg'

export default class CentralCommandControl extends Component {
    render() {
        return (
            <div data-slide="5" className="container px-0 pb-10">
                <div className="text-center mt-4 mb-5">
                    <h1 className="playfair-display-bold text-white">CENTRAL <span className="text-cyan playfair-display-bold">COMMAND AND CONTROL</span></h1>
                    <h6 className="text-white pt-lg-1 pt-3">Complete Intelligence Monitoring Centre</h6>
                </div>
                <div className="row mx-0">
                    <div className="col-lg-12">
                        <img src={completeIntelligenceControl} className="w-100" alt="" />
                    </div>
                </div>
                
            </div>
        )
    }
}
