import React, { Component } from 'react'

export default class CyanBorderedCard extends Component {
    render() {
        let { key, imgCyan, imgWhite, title, width, colLg, colSm } = this.props
        return (
            <div
                data-aos="zoom-out"
                data-aos-delay="400"
                data-aos-duration="800" 
                className={`col-lg-${(colLg || 3)} col-${(colSm || 6)} my-2`}>
                <div
                    key={key} 
                    className="w-100 px-lg-5 px-3 py-4 text-center border-radius-5 two__tone__card">
                    <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                        <div>
                            <img src={imgCyan} className="img__cyan" width={width} alt="" />
                            <img src={imgWhite} className="img__white" width={width} alt="" />
                            <p className="text-white pt-3 small mb-0 lh-175 ls-1">{title}</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
