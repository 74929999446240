import React, { Component } from 'react'
import HeroRapideye from '../components/hero/HeroRapideye'
import RapideyeIntro from '../components/intro/Rapideye'
import RapideyeInformationSources from '../components/RapideyeInformationSources'
import FiveStepProcess from '../components/FiveStepProcess'
import UniquePropositionRapideye from '../components/UniquePropositionRapideye'
import FusionCentre from '../components/FusionCentre'
import RapideyeServices from '../components/RapideyeServices'
import SaferWorld from '../components/SaferWorld'
import ContactInfo from '../components/ContactInfo'
import TitleDescButton from '../components/text/TitleDescButton'
import horizontalLine from '../assets/img/horizontal-line.svg'
import IndicatorsContext, { IndicatorsProvider } from '../context/IndicatorsContext'
import Indicators from '../components/Indicators'

import $ from 'jquery'

export default class Rapideye extends Component {

    componentDidMount() {
        document.title = "Rapideye | Owlsense"
        $('html, body').animate({
            scrollTop: 0
        }, 1000)
    }    

    render() {
        return (
            <div className="container-fluid px-0">

                <IndicatorsProvider>     
                    {/* Indicators showing... */}
                    <Indicators />                
                    <HeroRapideye />
                    <RapideyeIntro />
                    <RapideyeInformationSources />
                    <FiveStepProcess />
                    <UniquePropositionRapideye />
                    <FusionCentre />
                    <RapideyeServices />
                    <SaferWorld />

                    <ContactInfo />
                    {/* <TitleDescButton
                        heading="Want to know more?"
                        subHeading="Request a free demo to discover how OwlSense (Pvt.) Ltd. assures open-source/private intercepted data intelligence and delivers a validated, independent, impartial perspective on threats and investigations."
                    />
                    <div className="w-100 text-center pb-10 pt-5">
                        <img
                            data-aos="fade-up"
                            data-aos-delay="500"
                            data-aos-duration="500" 
                            src={horizontalLine} width="3px" height="77px" alt="Horizontal Line" />
                    </div>   */}
                </IndicatorsProvider>               
            </div>
        )
    }
}
Rapideye.contextType = IndicatorsContext
