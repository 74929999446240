import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import bg from '../assets/img/contact.png'
import $ from 'jquery'

export default class ContactInfo extends Component {

    componentDidMount() {

        if (this.props && this.props.params) {
            let { params } = this.props

            if (params.section == "reach-us") {
                $('html, body').animate({
                    scrollTop: $("#reachus").offset().top - $("#navbar").outerHeight() + 10
                }, 999)              
            }    
        }
    }

    render() {
        return (
            <div data-slide="-1" id="reachus" className="hero-container position-relative mb-5" style={{ background: `url(${bg})` }}>
                <div id="hero-body" className="flex-wrap position-relative pb-lg-5 pb-0">
                    <div className="container text-white text-center">
                        <div className="row mx-0 justify-content-center">
                            <div className="col-lg-7">
                                <h1 
                                    data-aos="fade-up"
                                    data-aos-delay="300"
                                    data-aos-duration="500"
                                    className="display-lg-4 playfair-display-bold text-white">Want to know more?</h1>
                                <p 
                                    data-aos="fade-up"
                                    data-aos-delay="500"
                                    data-aos-duration="500"
                                    className="medium text-light-2 py-4 mb-4 my-0 lh-175 font-weight-light"> Request a free demo to discover how <span class='font-weight-bold'>OwlSense (Pvt.) Ltd.</span> assures open-source/private intercepted data intelligence and delivers a validated, independent, impartial perspective on threats and investigations. </p>           
                                <form className="mt-4">
                                    <div
                                        data-aos="zoom-out"
                                        data-aos-delay="300"
                                        data-aos-duration="500" 
                                        className="form-group mb-4 pb-lg-3">
                                        <input type="text" className="form-control border-light2 py-3 px-4 bg-dark-opaque-3 border-radius__12 text-white placeholder__white" placeholder="Name" />
                                    </div>
                                    <div
                                        data-aos="zoom-out"
                                        data-aos-delay="300"
                                        data-aos-duration="500"  
                                        className="form-group mb-4 pb-lg-3">
                                        <input type="text" className="form-control border-light2 py-3 px-4 bg-dark-opaque-3 border-radius__12 text-white placeholder__white" placeholder="Email" />
                                    </div>
                                    <div
                                        data-aos="zoom-out"
                                        data-aos-delay="300"
                                        data-aos-duration="500"  
                                        className="form-group">
                                        <textarea 
                                            rows="4"
                                            style={{ resize: "none" }}
                                            className="form-control border-light2 py-3 px-4 bg-dark-opaque-3 border-radius__12 text-white placeholder__white" placeholder="Message"></textarea>
                                    </div>
                                </form>                                
                                <a
                                    data-aos="zoom-out"
                                    data-aos-delay="100"
                                    data-aos-duration="1000" 
                                    type="button"
                                    target="_blank"
                                    href="https://www.linkedin.com/jobs/search/?f_C=11802264&geoId=92000000" className="btn btn-info-2 px-5 py-3 ls-2 my-4 shadow-cyan border-rounded">
                                    <span className="small">SEND MESSAGE</span>
                                </a>                                                     
                            </div>
                        </div> 
                    </div>                     
                </div>
            </div>
        )
    }
}
