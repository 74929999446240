import React, { Component } from 'react'
import NationalSecurityIntelligence from '../components/capibilities/NationalSecurityIntelligence'
import PerceptionManagementIntelligence from '../components/capibilities/PerceptionManagementIntelligence'
import Tabs from '../components/capibilities/Tabs'
import ThreatIntelligence from '../components/capibilities/ThreatIntelligence'
import ContactInfo from '../components/ContactInfo'
import TitleDescButton from '../components/text/TitleDescButton'
import CapibilitiesTabContext, { CapibilitiesTabProvider } from '../context/CapibilitiesTabContext'
import horizontalLine from '../assets/img/horizontal-line.svg'

export default class Capabilities extends Component {
    
    componentDidMount() {
        document.title = "Capabilities | Owlsense"
    }  

    render() {
        return (
            <div data-slide="-1" className="container-fluid px-0 text-white pt-10">
                <div className="container px-0">
                    <h1 className="d-none d-lg-block display-4 playfair-display-bold text-center py-5">Our Capabilities</h1>
                    <h1 className="d-block d-lg-none playfair-display-bold text-center py-5">Our Capabilities</h1>
                    <div className="pb-10 mb-5">
                        <CapibilitiesTabProvider>
                            <Tabs 
                                params={this.props.match.params}
                            />
                            <ThreatIntelligence />
                            <NationalSecurityIntelligence />
                            <PerceptionManagementIntelligence />
                            <div className="w-100 my-5"></div>
                        </CapibilitiesTabProvider>   
                    </div>
                </div>
                <ContactInfo />
                <TitleDescButton
                    heading="Want to know more?"
                    subHeading="Request a free demo to discover how OwlSense (Pvt.) Ltd. assures open-source/private intercepted data intelligence and delivers a validated, independent, impartial perspective on threats and investigations."
                />
                <div className="w-100 text-center pb-lg-5 pt-5">
                    <img src={horizontalLine} width="3px" height="77px" alt="Horizontal Line" />
                </div>                        

            </div>
        )
    }
}
Capabilities.contextType = CapibilitiesTabContext
