import React, { Component } from 'react'
import img1 from '../assets/img/what-we-do/1.png'
import img2 from '../assets/img/what-we-do/2.png'
import img3 from '../assets/img/what-we-do/3.png'
import img4 from '../assets/img/what-we-do/4.png'
import dots from '../assets/img/dots.svg'
import $ from 'jquery'
import gradient from '../assets/img/gradient-185.png'

export default class WhatWeDo extends Component {
    componentDidMount() {
        let { params } = this.props

        if (params.section == "what-we-do") {
            $('html, body').animate({
                scrollTop: $("#whatwedo").offset().top - $("#navbar").outerHeight() + 10
            }, 699)              
        }
    }
    render() {
        return (
            <div className="container-fluid px-0">
                <div id="whatwedo" data-slide="1" className="container py-5">
                    <div className="row mx-0">
                        <div className="col-lg-5 mb-5 mb-lg-0 align-self-center">
                            <h1 
                                data-aos="zoom-in"
                                data-aos-anchor-placement="top-bottom"
                                data-aos-delay="200"
                                data-aos-duration="700"
                                className="text-white playfair-display-bold pb-3 display-lg-4">What We <span className="playfair-display-bold text-info-2">Do</span></h1>
                            <p 
                                data-aos="fade-up"
                                data-aos-anchor-placement="top-bottom"
                                data-aos-delay="200"
                                data-aos-duration="700"
                                className="text-light-blue-2 medium lh-175">
                                We’re living in an era of 5th generation warfare where words are more dangerous than bullets and where data can be used to engineer the perception of masses creating chaos. The ever-shifting <i>nature of intelligence</i>, increased amount of unreliable information, fake news and no central platform for investigation compels us to have intelligence tools to find verified, validated and unbiased threat intelligence.                             
                                </p>
                            <p 
                                data-aos="fade-up"
                                data-aos-anchor-placement="top-bottom"
                                data-aos-delay="500"
                                data-aos-duration="800"
                                className="text-light-blue-2  medium lh-175">
                                We offer end-to-end intelligent, investigative, open/private data based solutions to lawful organizations for monitoring and analysis. We provide unique & holistic insights, help uncover the hidden meaningful information timely & efficiently and turn the acquired information into actionable intelligence by automatic analysis and processing.                        
                                </p>
                        </div>
                        <div className="col-lg-7 p-lg-5 p-2">
                            <div className="row mx-0 w-100 justify-content-end">
                                <div className="col-lg-11 col-11 px-0">
                                    <div className="row mx-0">
                                        <div 
                                            data-aos="zoom-in"
                                            data-aos-anchor-placement="top-bottom"
                                            data-aos-duration="600"
                                            className="col-lg-6 col-6 px-0"><img src={img1} className="w-100" alt="" /></div>
                                        <div
                                            data-aos="zoom-in"
                                            data-aos-anchor-placement="top-bottom"
                                            data-aos-delay="100"
                                            data-aos-duration="500" 
                                            className="col-lg-6 col-6 px-0"><img src={img2} className="w-100 opacity-0-9x" alt="" /></div>
                                        <div
                                            data-aos="zoom-in"
                                            data-aos-delay="200"
                                            data-aos-duration="500"  
                                            className="col-lg-6 col-6 px-0"><img src={img3} className="w-100 opacity-0-9x" alt="" /></div>
                                        <div 
                                            data-aos="zoom-in"
                                            data-aos-delay="300"
                                            data-aos-duration="500"  
                                            className="col-lg-6 col-6 px-0"><img src={img4} className="w-100 opacity-0-9x" alt="" /></div>
                                    </div>
                                </div>
                                <div
                                    data-aos="zoom-in"
                                    data-aos-delay="500"
                                    data-aos-duration="500" 
                                    className="col-lg-1 col-1 align-self-center pl-lg-1 px-0">
                                    <img src={dots} width="100%" height="auto" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
