import React, { Component } from 'react'

const IndicatorsContext = React.createContext()

export class IndicatorsProvider extends Component {
    state = {        
        index: 0
    }
    
    setIndex = (index) => {
        this.setState({
            index
        })
    }

    render() {
        const { index } = this.state
        const { setIndex } = this
        return(
            <IndicatorsContext.Provider value={{
                index,
                setIndex
            }}>
                {this.props.children}
            </IndicatorsContext.Provider>
        )
    }
}

export default IndicatorsContext