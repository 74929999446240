import React, { Component } from 'react'
import $ from 'jquery'
import IndicatorsContext from '../context/IndicatorsContext'

export default class Indicators extends Component {
    state = {
        i: 0,
        total: 0,
        class: ""
    }
    scrollTo(slideNo) {
        $('html, body').animate({
            scrollTop: $(`[data-slide=${slideNo}]`).offset().top - $("#navbar").outerHeight() + 10
        }, 699)            
    }

    componentDidMount() {
        let $this = this,
            slides = $("[data-slide]"),
            navbar = $("#navbar")

        this.setState({
            ...this.state,
            total: slides.filter(function() { return parseInt($(this).attr("data-slide")) >= 0 }).length
        })
        $(document).ready(function() {
            $(window).on('scroll', function() {
                let scrollTop = $(this).scrollTop()

                slides.each((i, slide) => {
                    if ($(slide).attr("data-slide") == -1) {
                        $this.setState({
                            ...this.state
                        })
                    }
                    else if ((scrollTop + navbar.outerHeight()) > $(slide).offset().top) {
                        $this.setState({
                            ...this.state,
                            i
                        })
                    }
                });
            })        
    
        })        
    }
    render() {
        return (
            <div className={"position-fixed z-3000 right-0 pr-5 mr-4 d-none d-lg-block" + this.state.class} style={{ top: "50%" }}>
                {this.state && [...Array(this.state.total)].map((_, i) => (
                    <a
                        onClick={() => this.scrollTo(i)} 
                        href="javascript:void(0)" 
                        className={"btn-block my-0 mdi text-info-2 transition-2 " + (i == this.state.i ? " mdi-record-circle-outline scale-2" : "mdi-circle-medium")}></a>

                ))}
                {/* <a href="javascript:void(0)" className="btn-block my-0 mdi  text-info-2"></a>
                <a href="javascript:void(0)" className="btn-block my-0 mdi mdi-circle-medium text-info-2"></a>
                <a href="javascript:void(0)" className="btn-block my-0 mdi mdi-circle-medium text-info-2"></a>                 */}
            </div>          
        )
    }
}

Indicators.contextType = IndicatorsContext