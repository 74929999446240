import React, { Component } from 'react'
import TitleSubtitleDescription from '../text/TitleSubtitleDescription'
import img1 from '../../assets/img/rapideye-intro/1.png'
import img2 from '../../assets/img/rapideye-intro/2.png'
import dots from '../../assets/img/dots.svg'

export default class Rapideye extends Component {
    render() {
        return (
            <div data-slide="1" className="container px-0">
                <div className="row mx-0 py-10">
                    <div className="col-md-6 pr-3">
                        <TitleSubtitleDescription
                            heading="Intelligence Fusion Centre"
                            subHeading="Home Land Security and Law Enforcement <br> Solution"
                            description="
                                We offer a cutting-edge <strong>Fusion Centre</strong> solution, an all in one intelligence monitoring system, by which we can help uncover intelligence quickly and efficiently and turn the retrieved information into actionable intelligence by automatic analysis and processing. 
                                <br><br>
                                The <strong>Main purpose</strong> of implementation of RapidEye in phases is to achieve step by step control on terrorism, Kidnapping, Insurgency , Anti-State and Anti-Government moments with passive monitoring and tracking of suspects and integration of all existing and upcoming intelligence tools. 
                                <br><br>
                                With <strong><u>RapidEye</u></strong> we present an effective and efficient Intelligence tool to gather, exchange & analyse information and intelligence across a variety of heterogeneous resources and provide a unique perspective on criminal & terrorist threats/investigations to the state agencies in order to enable high-level of Home Land Security and Law enforcement.    
                            "/>
                    </div>
                    <div className="col-md-6 align-self-center pl-lg-3 pl-0 pr-0 mt-5 mt-lg-0">
                        <div className="row mx-0">
                            <div className="col-11 px-0">
                                <div className="row mx-0">
                                    <div
                                        data-aos="zoom-out"
                                        data-aos-duration="500" 
                                        data-aos-delay="200" 
                                        className="col-7 px-lg-2 pl-0 pr-2"><img src={img1} className="w-100 border-radius-5" alt="" /></div>
                                    <div
                                        data-aos="zoom-out"
                                        data-aos-duration="500" 
                                        data-aos-delay="400" 
                                        className="col-5 pr-0 pl-lg-1 pl-0 align-self-center"><img src={img2} className="w-100 border-radius-5" alt="" /></div>
                                </div>
                            </div>
                            <div className="col-1 align-self-center pr-0 pl-lg-2 pl-1">
                                <img
                                    data-aos="zoom-out"
                                    data-aos-duration="500" 
                                    data-aos-delay="600" 
                                    src={dots} width="100%" height="auto" alt="" />
                            </div>
                        </div>                    
                    </div>
                </div>

            </div>
        )
    }
}
