import React, { Component } from 'react'

import img1 from '../assets/img/fusion-centre/1.png'
import img2 from '../assets/img/fusion-centre/2.png'
import img3 from '../assets/img/fusion-centre/3.png'
import UnorderedList from './widgets/UnorderedList'

export default class FusionCentre extends Component {
    render() {
        return (
            <div data-slide="5" className="container px-0 py-lg-5 py-4 my-lg-5">
                <div className="text-center">
                    <h6 className="text-cyan">WHAT WE OFFER</h6>
                    <h1 className="playfair-display-bold text-white text-lg-h1">Perks of Fusion Centre</h1>
                </div>
                <div className="row mx-0 py-5">
                    <div className="col-lg-6 px-0">
                        <div className="row mx-0">
                            <div className="col-lg-6 col-6 pr-lg-3 pr-2 pl-0">
                                <img
                                    data-aos="zoom-out"
                                    data-aos-delay="100"
                                    data-aos-duration="700"  
                                    src={img1} className="w-100 mb-lg-2 mb-2" alt="" />
                                <img
                                    data-aos="zoom-out"
                                    data-aos-delay="200"
                                    data-aos-duration="700" 
                                    src={img2} className="w-100 pt-lg-3 pt-1" alt="" />
                            </div>
                            <div className="col-lg-6 col-6 pl-1 align-self-center pr-0">
                                <img
                                    data-aos="zoom-out"
                                    data-aos-delay="500"
                                    data-aos-duration="700" 
                                    src={img3} className="w-100" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 pr-0 pl-lg-5 pl-4 pt-lg-0 pt-5">
                        <UnorderedList 
                            desc="The system is designed for intelligence professionals, emphasizing real world use cases and operational workflows. Following are the key features that we present."
                            checklist={
                                [
                                    "Fusion and AI based Coorelation",
                                    "Multiple Database Integration",
                                    "Powerful Insights & Intuitive Interface",
                                    "Smart Search (Manual & Automatic Analysis)",
                                    "Image Analytics, Text Analytics (<strong>Rapidox</strong>)",
                                    "Crime Mapping & Pattern Recognition",
                                    "Geofencing Based Analytics",
                                    "Role Based Access",
                                    "Highly Customizable and Scalable",
                                    "Video/Audio transcription & Speaker Recognition",
                                    "Automatic Reporting",
                                    "Integeration with Interception Solutions",
                                ]
                            }
                        />
                    </div>
                </div>
            </div>
        )
    }
}
