import React, { Component } from 'react'

import search from '../assets/img/search.svg'
import menu from '../assets/img/menu.svg'
import close from '../assets/img/close.svg'
import logoWhite from '../assets/img/logo/main-white.svg'

import facebook from '../assets/img/social/facebook.svg'
import linkedin from '../assets/img/social/linkedin.svg'
import twitter from '../assets/img/social/twitter.svg'
import instagram from '../assets/img/social/instagram.svg'

import { Link } from 'react-router-dom'
import $ from 'jquery'

export default class Navbar extends Component {
    state = {
        page: null,
        links: [
            // { name: "PRODUCTS", href: "/products" },
            // { name: "CASE STUDIES", href: "/resources" },
        ],
        bgColor: '',
        textColor: 'text-white',
        maxHeight: '500px',
        logo: logoWhite,
        navOpen: false,
        searchOpen: false,
        active: null,
    }  
    scrollTop() {
        $('html, body').animate({
            scrollTop: 0
        }, 699) 
    }  
    componentDidMount() {
        let $this = this
        this.setState({
            ...this.state,
            page: window.location.pathname
        })
        $(document).ready(function() {
            $(window).on('scroll', function() {
                let scrollTop = $(this).scrollTop(),
                    h = $(this).height()
                if (scrollTop > (h * 0.10)) {
                    $this.setState({
                        ...$this.state,
                        bgColor: 'bg-main-opaque shadow',
                        maxHeight: '0px',
                        logo: logoWhite,
                        active: null,
                    })
                } else {
                    $this.setState({
                        ...$this.state,
                        bgColor: '',
                        maxHeight: '400px',
                        logo: logoWhite,
                        active: null,
                    })
                }
            })        

            $("#mobile__view__container").find("*")
            .click((ev) => {
                let tagName = $(ev.currentTarget).prop("tagName") 

                if (tagName == "A") {
                    $this.setState({
                        ...$this.state,
                        navOpen: false,
                        searchOpen: false 
                    })
                }
            })
    
        })
    }

    render() {
        let { textColor, bgColor, navOpen, searchOpen, active } = this.state
        $("body").css({
            overflowY: navOpen ? "hidden" : "auto"
        })
        return (
            <div className="position-fixed z-3000 w-100">
                <div id="navbar" className={"container-fluid 100 px-0 transition pb-1 " + bgColor + ((navOpen || searchOpen) ? " bg-main " : "")}>
                    <div className="container-fluid pt-0">
                        <div className="row mx-0 align-items-center justify-content-between">
                            <div className="col-md-1"></div>
                            <div className="col-md-2 col-6 px-0">
                                <Link   
                                    onClick={() => this.scrollTop()} 
                                    to="/" className="btn px-lg-2 pr-4">
                                    <img src={this.state.logo} className="w-lg-60 px-lg-5 w-100 transition" alt="" />
                                </Link>                            
                            </div>
                            <div className="col-md-5 text-center  px-0 align-self-center d-none d-lg-block mt-2">
                                <div class="dropdown d-inline-block">
                                    <a 
                                        onClick={() => this.setState({...this.state, active: "ABOUT US"})}
                                        key={2}
                                        href="javascript:void(0)" 
                                        className={
                                            "btn mx-3 px-2 ls-2 text-white " 
                                            + (active === "ABOUT US" ? " underlined " : "")
                                        }                                
                                        type="button" id="aboutDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span className="small montserrat-semibold">ABOUT US</span>
                                    </a>
                                    <div class="dropdown-menu border-0 border-radius-0 bg-primary-opaque text-white py-3 ">
                                        <Link to="/what-we-do" class="dropdown-item text-white small py-2">What We Do</Link>
                                        <Link to="/national-security" class="dropdown-item text-white small py-2">How We Serve National Security</Link>
                                        <Link to="/perception-management" class="dropdown-item text-white small py-2">How We Provide Perception Management</Link>
                                    </div>
                                </div>

                                <div class="dropdown d-inline-block">
                                    <a 
                                        onClick={() => this.setState({...this.state, active: "CAPABILITIES"})}
                                        key={1}
                                        href="javascript:void(0)" 
                                        className={
                                            "btn mx-4 px-1 ls-2 text-white " 
                                            + (active === "CAPABILITIES" ? " underlined " : "")
                                        }                                
                                        type="button" id="aboutDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span className="small montserrat-semibold">CAPABILITIES</span>
                                    </a>
                                    <div class="dropdown-menu border-0 border-radius-0 bg-primary-opaque text-white py-2" style={{ width: "560px"}}>
                                        <div className="row mx-0 px-3 py-4">
                                            <div className="col-lg-6 px-0">
                                                <h6 className="text-white small px-2 mb-3">Threat Intelligence</h6>
                                                <Link to="/capabilities/threat_intelligence/terrorism_threat_investigation" class="dp-item d-block py-1 px-2 text-light-blue small hover-none">Terrorism and threat investigation</Link>
                                                <Link to="/capabilities/threat_intelligence/propaganda_investigation" class="dp-item d-block py-1 px-2 text-light-blue small">Propaganda Investigation</Link>
                                                <Link to="/capabilities/threat_intelligence/crime_geomapping" class="dp-item d-block py-1 px-2 text-light-blue small">Crime Geomapping</Link>
                                                <div className="w-100 my-4"></div>
                                                <h6 className="text-white small px-2 mb-3">Perception Management Intelligence</h6>
                                                <Link to="/capabilities/perception_management_intelligence/narrative_influencers_analysis" class="dp-item d-block py-1 px-2 text-light-blue small">Narrative And Influencers Analysis</Link>
                                                <Link to="/capabilities/perception_management_intelligence/reputation_intelligence" class="dp-item d-block py-1 px-2 text-light-blue small">Reputation Intelligence</Link>
                                            </div>
                                            <div className="col-lg-6 px-0">
                                                <h6 className="text-white small px-2">National Security Intelligence</h6>
                                                <Link to="/capabilities/national_security_intelligence/law_order_maintainance" class="dp-item d-block py-1 px-2 text-light-blue small">Law & Order Maintainance</Link>
                                                {/* <Link to="/capabilities/national_security_intelligence/satellite_monitoring_system" class="dp-item d-block py-1 px-2 text-light-blue small">Satellite Monitoring System</Link> */}
                                                <Link to="/capabilities/national_security_intelligence/national_database_fusion_centre" class="dp-item d-block py-1 px-2 text-light-blue small">National Database Fusion Centre</Link>
                                                <Link to="/capabilities/national_security_intelligence/cdr_interception" class="dp-item d-block py-1 px-2 text-light-blue small">CDR Interception</Link>
                                                <Link to="/capabilities/national_security_intelligence/kidnapping_missing_persons_investigation" class="dp-item d-block py-1 px-2 text-light-blue small">Kidnapping & Missing Persons Investigation</Link>
                                                <Link to="/capabilities/national_security_intelligence/external_threats_detection" class="dp-item d-block py-1 px-2 text-light-blue small">External Threats Detection</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="dropdown d-inline-block">
                                    <a 
                                        onClick={() => this.setState({...this.state, active: "PRODUCTS"})}
                                        href="javascript:void(0)" 
                                        key={3}
                                        className={
                                            "btn mx-4 px-1 ls-2 text-white " 
                                            + (active === "PRODUCTS" ? " underlined " : "")
                                        }                          
                                        type="button" id="aboutDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span className="small montserrat-semibold">PRODUCTS</span>
                                    </a>
                                    <div class="dropdown-menu border-0 border-radius-0 bg-primary-opaque text-white py-3 ">
                                        <Link to="/owlsense" class="dropdown-item text-white small py-2">OwlSense AI</Link>
                                        <Link to="/rapideye" class="dropdown-item text-white small py-2">RapidEye Software</Link>
                                        {/* <Link to="/thuraya-satellite" class="dropdown-item text-white small py-2">Satellite Monitoring System</Link> */}
                                        <Link to="/perception-management-tool" class="dropdown-item text-white small py-2">Perception Management Tool</Link>
                                    </div>
                                </div>
                                {this.state.links ? this.state.links.map((link, key) => (
                                    <Link
                                        onClick={() => this.setState({...this.state, page: link.href})} 
                                        key={key}
                                        to={link.href} 
                                        className={
                                            "btn mx-4 px-1 pb-0" 
                                            + (this.state.page == link.href ? " underlined " : " underlined-transparent ")
                                            + textColor
                                        
                                        }><span className="small montserrat-semibold">{link.name}</span></Link>
                                )) : null}       
                            </div>
                            <div className="col-md-2 col-6 align-self-center mt-2 ">
                                <div className="d-flex align-items-center justify-content-lg-center justify-content-end">
                                    <div class="dropdown d-none">
                                        <a 
                                            href="javascript:void(0)" 
                                            className={
                                                "btn px-1 " 
                                                + textColor
                                            }                                
                                            type="button" id="aboutDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <span className="small montserrat-semibold">ENGLISH <span className="ml-1">&#9207;</span></span>
                                        </a>
                                        <div class="dropdown-menu border-0 border-radius-0 bg-primary-opaque text-white py-3 ">
                                            <a class="dropdown-item text-white small py-2" href="javascript:void(0)">Urdu</a>
                                            <a class="dropdown-item text-white small py-2" href="javascript:void(0)">Pashto</a>
                                            <a class="dropdown-item text-white small py-2" href="javascript:void(0)">Persian</a>
                                        </div>
                                    </div>
                                    {!searchOpen ? 
                                        <a 
                                            onClick={() => this.setState({
                                                ...this.state,
                                                searchOpen: !this.state.searchOpen,
                                                navOpen: false
                                            })}
                                            href="javascript:void(0)" className="ml-2 d-none">
                                            <img src={search} width="22px" alt="" />
                                        </a>                                      
                                    : ""}
                                    <a 
                                        onClick={() => this.setState({
                                            ...this.state,
                                            searchOpen: false,
                                            navOpen: !this.state.navOpen
                                        })}
                                        href="javascript:void(0)" className="ml-3 d-inline-block d-lg-none">
                                        <img src={navOpen ? close : menu} width="22px" alt="" />
                                    </a>                            
                                </div>
                            </div>
                            <div className="col-lg-1"></div>
                        </div>
                    </div>
                </div>                
                <div id="mobile__view__container" className="w-100 bg-primary-opaque position-relative transition" style={{ maxHeight: navOpen ? "1000px" : "0px", overflowY: "hidden" }}>
                    <div id="accordion" className="py-2 px-2 overflow-auto nav__dropdown__height">
                        <div class="card bg-transparent border-0 border-bottom-secondary border-radius-0">
                            <div class="card-header px-2 border-0 pb-1" id="headingOne">
                                <h5 class="mb-0">
                                    <button class="btn text-white ls-2 d-flex w-100 justify-content-between align-items-center px-2" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        <span>ABOUT US</span>
                                        <span className="mdi mdi-chevron-right mdi-24px"></span>
                                    </button>
                                </h5>
                            </div>

                            <div id="collapseOne" class="collapse collapsed" aria-labelledby="headingOne" data-parent="#accordion">
                                <div class="card-body mt-0 pt-0 mb-3">
                                    <div className="w-25 border-bottom-cyan border-w2 mb-2 mt-1"></div>
                                        <Link to="/what-we-do" class="d-block text-white small py-2">What We Do</Link>
                                        <Link to="/national-security" class="d-block text-white small py-2">How We Serve National Security</Link>
                                        <Link to="/perception-management" class="d-block text-white small py-2">How We Provide Perception Management</Link>
                                </div>
                            </div>
                        </div>
                        <div class="card bg-transparent border-0 border-bottom-secondary border-radius-0">
                            <div class="card-header px-2 border-0 pb-1" id="headingTwo">
                                <h5 class="mb-0">
                                    <button class="btn text-white ls-2 d-flex w-100 justify-content-between align-items-center px-2" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                        <span>CAPABILITIES</span>
                                        <span className="mdi mdi-chevron-right mdi-24px"></span>
                                    </button>
                                </h5>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                <div class="card-body mt-0 pt-0 mb-3">
                                    <div className="w-25 border-bottom-cyan border-w2 mb-2 mt-1"></div>
                                    <div className="row mx-0 px-0 py-4">
                                        <div className="col-lg-6 px-0">
                                            <h6 className="text-white small">Threat Intelligence</h6>
                                            <Link to="/capabilities/threat_intelligence/terrorism_threat_investigation" class="dp-item d-block py-2 text-light-blue small hover-none">Terrorism and threat investigation</Link>
                                            <Link to="/capabilities/threat_intelligence/propaganda_investigation" class="dp-item d-block py-2 text-light-blue small">Propaganda Investigation</Link>
                                            <Link to="/capabilities/threat_intelligence/crime_geomapping" class="dp-item d-block py-2 text-light-blue small">Crime Geomapping</Link>
                                            <div className="w-100 my-4"></div>
                                            <h6 className="text-white small">Perception Management Intelligence</h6>
                                            <Link to="/capabilities/perception_management_intelligence/narrative_influencers_analysis" class="dp-item d-block py-2 text-light-blue small">Narrative And Influencers Analysis</Link>
                                            <Link to="/capabilities/perception_management_intelligence/reputation_intelligence" class="dp-item d-block py-2 text-light-blue small">Reputation Intelligence</Link>
                                            <div className="w-100 my-4"></div>
                                            <h6 className="text-white small">National Security Intelligence</h6>
                                            <Link to="/capabilities/national_security_intelligence/law_order_maintainance" class="dp-item d-block py-2 text-light-blue small">Law & Order Maintainance</Link>
                                            {/* <Link to="/capabilities/national_security_intelligence/satellite_monitoring_system" class="dp-item d-block py-2 text-light-blue small">Satellite Monitoring System</Link> */}
                                            <Link to="/capabilities/national_security_intelligence/national_database_fusion_centre" class="dp-item d-block py-2 text-light-blue small">National Database Fusion Centre</Link>
                                            <Link to="/capabilities/national_security_intelligence/cdr_interception" class="dp-item d-block py-2 text-light-blue small">CDR Interception</Link>
                                            <Link to="/capabilities/national_security_intelligence/kidnapping_missing_persons_investigation" class="dp-item d-block py-2 text-light-blue small">Kidnapping & Missing Persons Investigation</Link>
                                            <Link to="/capabilities/national_security_intelligence/external_threats_detection" class="dp-item d-block py-2 text-light-blue small">External Threats Detection</Link>
                                        </div>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                        <div class="card bg-transparent border-0 border-bottom-secondary border-radius-0">
                            <div class="card-header px-2 border-0 pb-0" id="headingThree">
                                <h5 class="mb-0">
                                    <button class="btn text-white ls-2 d-flex w-100 justify-content-between align-items-center px-2" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                                        <span>PRODUCTS</span>
                                        <span className="mdi mdi-chevron-right mdi-24px"></span>
                                    </button>
                                </h5>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                                <div class="card-body mt-0 pt-0 mb-3">
                                    <div className="w-25 border-bottom-cyan border-w2 mb-2 mt-1"></div>
                                    <Link to="/owlsense" class="d-block text-white small py-2">OwlSense AI</Link>
                                    <Link to="/rapideye" class="d-block text-white small py-2">RapidEye Software</Link>
                                    {/* <Link to="/thuraya-satellite" class="d-block text-white small py-2">Satellite Monitoring System</Link> */}
                                    <Link to="/perception-management-tool" class="d-block text-white small py-2">Perception Management Tool</Link>           
                                </div>                                
                            </div>
                        </div>
                    </div>     
                    <div className="row mx-0 w-100 text-center border-top-secondary position-absolute bottom-0 bg-primary-opaque d-none">
                        <div className="col-3 p-4 border-right-secondary">
                            <a href="https://www.facebook.com/Rapidev.Official/" target="_blank">
                                <img src={facebook} alt="" className="p-2 w-100" />
                            </a>
                        </div>
                        <div className="col-3 p-4 border-right-secondary">
                            <a href="https://twitter.com/rapidevd" target="_blank">
                                <img src={twitter} alt="" className="p-2 w-100" />
                            </a>
                        </div>
                        <div className="col-3 p-4 border-right-secondary">
                            <a href="https://www.instagram.com/rapidev.pk" target="_blank">
                                <img src={instagram} alt="" className="p-2 w-100" />
                            </a>
                        </div>
                        <div className="col-3 p-4 border-right-secondary">
                            <a href="https://www.linkedin.com/company/rapidev/mycompany/" target="_blank">
                                <img src={linkedin} alt="" className="p-2 w-100" />
                            </a>
                        </div>
                    </div>               
                </div>          
                <div className="w-100 bg-primary-opaque position-relative transition pl-4 pr-3 py-0" style={{ maxHeight: searchOpen ? "1000px" : "0px", overflowY: "hidden" }}>                    
                    <div className="input-group py-4">
                        <input type="text" className="form-control bg-transparent border-0 border-radius-0 border-bottom-secondary white-placeholder text-white" placeholder="Search" />
                        <div className="input-group-append px-2">
                            <span
                                onClick={() => this.setState({
                                    ...this.state,
                                    searchOpen: false
                                })} 
                                className="btn input-group-text border-0">                                
                                <img src={close} width="16px" alt="" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
