import React, { Component } from 'react'
import mockup from '../assets/img/mockup.png'

export default class PerceptionManagementMockup extends Component {
    render() {
        return (
            <div data-slide="4" className="container px-0 py-lg-5 py-4 mt-lg-5 mb-5">
                <div className="row mx-0">
                    <div className="col-lg-12">
                        <img src={mockup} className="w-100" alt="" />
                    </div>
                </div>                
            </div>
        )
    }
}
