import React, { Component } from 'react'
import ContactInfo from '../components/ContactInfo'
import TitleDescButton from '../components/text/TitleDescButton'
import horizontalLine from '../assets/img/horizontal-line.svg'
import PerceptionManagementToolIntro from '../components/intro/PerceptionManagementTool'
import IndicatorsContext, { IndicatorsProvider } from '../context/IndicatorsContext'
import Indicators from '../components/Indicators'
import HeroPerceptionManagementTool from '../components/hero/HeroPerceptionManagementTool'
import SocialMediaAccountControl from '../components/SocialMediaAccountControl'
import $ from 'jquery'
import PerceptionManagementMockup from '../components/PerceptionManagementMockup'

export default class PerceptionManagementTool extends Component {

    componentDidMount() {
        document.title = "Perception Management Tool | Owlsense"
        $('html, body').animate({
            scrollTop: 0
        }, 1000)
    }  

    render() {
        return (
            <div className="container-fluid px-0">

                <IndicatorsProvider>     
                    
                    {/* Indicators showing... */}
                    <Indicators /> 

                    <HeroPerceptionManagementTool />
                    <PerceptionManagementToolIntro />
                    <SocialMediaAccountControl />
                    <PerceptionManagementMockup />

                    <ContactInfo />
                    {/* <TitleDescButton
                        heading="Want to know more?"
                        subHeading="Request a free demo to discover how OwlSense (Pvt.) Ltd. assures open-source/private intercepted data intelligence and delivers a validated, independent, impartial perspective on threats and investigations."
                    />
                    <div className="w-100 text-center pb-10 pt-5">
                        <img src={horizontalLine} width="3px" height="77px" alt="Horizontal Line" />
                    </div>                  */}
                </IndicatorsProvider>
            </div>
        )
    }
}
PerceptionManagementTool.contextType = IndicatorsContext
