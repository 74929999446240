import React, { Component } from 'react'

export default class UnorderedList extends Component {
    render() {
        let { desc, checklist } = this.props 
        return (
            <div>
                <p 
                    data-aos="zoom-out"
                    data-aos-anchor-placement="top-center"
                    data-aos-delay="300"
                    data-aos-duration="700"
                    className="text-secondary medium mt-5 mb-4 my-lg-0 ls-1">{desc}</p>
                <ul 
                    data-aos="fade-left"
                    data-aos-anchor-placement="top-center"
                    data-aos-delay={200}
                    data-aos-duration="700"
                    className="text-secondary medium my-4 list-style-none">
                    {checklist && checklist.map((item, i) => (
                        <li 
                            key={i}
                            className="mb-1 d-flex ls-1">
                                <span className="mdi mdi-24px mdi-check text-cyan mr-2 px-1"></span> 
                                <span className="align-self-center" dangerouslySetInnerHTML={{ __html: item }}></span>
                        </li>

                    ))}
                </ul>                
            </div>
        )
    }
}
