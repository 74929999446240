import React, { Component } from 'react'
import bg from '../assets/img/rapideye-information-sources/bg.png'

import gsmWhite from '../assets/img/rapideye-information-sources/gsm-white.svg'
import gsmCyan from '../assets/img/rapideye-information-sources/gsm-cyan.svg'
import imsiWhite from '../assets/img/rapideye-information-sources/imsi-white.svg'
import imsiCyan from '../assets/img/rapideye-information-sources/imsi-cyan.svg'
import osintWhite from '../assets/img/rapideye-information-sources/osint-white.svg'
import osintCyan from '../assets/img/rapideye-information-sources/osint-cyan.svg'
import rtmsWhite from '../assets/img/rapideye-information-sources/rtms-white.svg'
import rtmsCyan from '../assets/img/rapideye-information-sources/rtms-cyan.svg'
import tacticalWhite from '../assets/img/rapideye-information-sources/tactical-white.svg'
import tacticalCyan from '../assets/img/rapideye-information-sources/tactical-cyan.svg'
import webForumWhite from '../assets/img/rapideye-information-sources/web-forum-white.svg'
import webForumCyan from '../assets/img/rapideye-information-sources/web-forum-cyan.svg'

import gradientTransitionTop from '../assets/img/gradient-transition-top.svg'
import gradientTransitionBottom from '../assets/img/gradient-transition-bottom.svg'
import CyanBorderedCard from './widgets/CyanBorderedCard'

export default class RapideyeInformationSources extends Component {
    state = {
        list: [
            { width:"65px", imgCyan: gsmCyan, imgWhite: gsmWhite, title: "GSM AND LANDLINE INTERCEPTION" },
            { width:"52px", imgCyan: tacticalCyan, imgWhite: tacticalWhite, title: "SIGNAL AND TACTICAL INTELLIGENCE SYSTEM" },
            // { width:"52px", imgCyan: rtmsCyan, imgWhite: rtmsWhite, title: "SATELLITE INTERCEPTION SYSTEM (RTMS)" },
            { width:"52px", imgCyan: osintCyan , imgWhite: osintWhite, title: "SOCIAL MEDIA OPEN SOURCE DATA (OSINT)" },
            { width:"52px", imgCyan: webForumCyan, imgWhite: webForumWhite, title: "NEWS AND WEB FORUM DATA" },
            { width:"52px", imgCyan: imsiCyan, imgWhite: imsiWhite, title: "IMSI GRABBING SYSTEMS" }
        ]
    }    
    render() {
        return (
            <div data-slide="2" id="hero-container"  className="container-fluid py-10 mt-5" style={{ background: `url(${bg})`}}>
                <img src={gradientTransitionBottom} className="w-100 bottom-0 left-0 position-absolute" alt="" />
                <img src={gradientTransitionTop} className="w-100 top-0 left-0 position-absolute" alt="" />                
                <div id="hero-body" style={{ height: "auto" }}>
                    <div className="w-100 text-center">
                        <div className="container text-center pt-lg-5 px-0 px-lg-2 ">
                            <div className="row mx-0 justify-content-center">
                                <div className="col-lg-8 px-0 px-lg-2">
                                    <h2 className="text-lg-h1 text-white playfair-display-bold">RapidEye - <span className="playfair-display-bold text-info-2">Information Sources</span></h2>
                                    <p className="text-secondary medium mt-4 pt-2 lh-175">
                                        RapidEye has capability of a vast range of systems that it can integrate and fuse intelligence from. Optionally, we can provide integration with sources such as 
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="container my-5">
                            <div className="row mx-0">
                                {this.state && this.state.list.map((item, i) => (
                                    <CyanBorderedCard
                                        colLg={4}
                                        colSm={12}
                                        key={i}
                                        imgCyan={item.imgCyan} 
                                        imgWhite={item.imgWhite}
                                        title={item.title} 
                                        width={item.width}
                                    />
                                ))}                        
                            </div>
                        </div>                                     
                    </div>                 
                </div>
            </div>
        )
    }
}
