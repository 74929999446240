import React, { Component } from 'react'
import saferWorld from '../assets/img/safer-world.svg'

export default class SaferWorld extends Component {
    render() {
        return (
            <div data-slide="7" className="container px-0 py-lg-5 py-4 mt-lg-5 mb-5">
                <div className="text-center mt-4 mb-5">
                    <h1 className="playfair-display-bold text-white">ENSURING A <span className="text-cyan playfair-display-bold">SAFER WORLD!</span></h1>
                    <h6 className="text-white pt-1">Home Land Security And Law Enforcement Solution</h6>
                </div>
                <div className="row mx-0">
                    <div className="col-lg-12">
                        <img src={saferWorld} className="w-100" alt="" />
                    </div>
                </div>                
            </div>
        )
    }
}
