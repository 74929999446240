import React, { Component } from 'react'
import CapibilitiesTabContext from '../../context/CapibilitiesTabContext'

import reputationManagement from '../../assets/img/gifs/noncopyright/Reputation Management.gif'
import influencerAnalysis from '../../assets/img/gifs/noncopyright/narrative and influencer analysis.gif'
import dots from '../../assets/img/dots2.svg'
import bg from '../../assets/img/capibilities/bg.png'
import GifCardDesign from '../widgets/GifCardDesign'

export default class PerceptionManagementIntelligence extends Component {
    state = {
        capibilities: [
            { img: influencerAnalysis, sub: "what are they saying!", value: "narrative_influencers_analysis", title: "Narrative and Influencer Analysis  ", desc: "Narrative Analysis on social media, influencer score, sentiment analysis, categorization, graph & link analysis, identify source, build counter-narrative, people sentiments on social media regarding new policy or law."},
            { img: reputationManagement, sub: "we got you!", value: "reputation_intelligence", title: "Reputation Intelligence", desc: "Social media management, reputation assessment, build positive image, brand launch, find out the conspiring agents.   "},
        ]
    }
    render() {
        const { tab } = this.context
        return (
            tab.value == "perception_management_intelligence" ?
                <div> 
                    {this.state && this.state.capibilities.map((capibility, i) => (
                        <GifCardDesign
                            key={i}
                            img={capibility.img}
                            sub={capibility.sub}
                            value={capibility.value}
                            title={capibility.title}
                            desc={capibility.desc}
                        />

                    ))}
                </div>
            : null
        )
    }
}
PerceptionManagementIntelligence.contextType = CapibilitiesTabContext
