import React, { Component } from 'react'
import TitleSubtitleDescription from '../text/TitleSubtitleDescription'
import owl from '../../assets/img/owl-flying.png'
import owl1 from '../../assets/img/owl-parts/owl1.png'
import owl2 from '../../assets/img/owl-parts/owl2.png'
import owl3 from '../../assets/img/owl-parts/owl3.png'
import owl4 from '../../assets/img/owl-parts/owl4.png'
import owl5 from '../../assets/img/owl-parts/owl5.png'
import owl6 from '../../assets/img/owl-parts/owl6.png'
import owl7 from '../../assets/img/owl-parts/owl7.png'
import owl8 from '../../assets/img/owl-parts/owl8.png'
import owl9 from '../../assets/img/owl-parts/owl9.png'
import owl10 from '../../assets/img/owl-parts/owl10.png'

export default class Owlsense extends Component {
    state = {
        parts: [
            { owl: owl1, delay: 300 },
            { owl: owl2, delay: 400 },
            { owl: owl3, delay: 500 },
            { owl: owl4, delay: 600 },
            { owl: owl5, delay: 700 },
            { owl: owl6, delay: 800 },
            { owl: owl7, delay: 900 },
            { owl: owl8, delay: 1000 },
            { owl: owl9, delay: 1100 },
            { owl: owl10, delay: 1200 },
        ]
    }
    // componentDidMount() {
    //     const $card = document.querySelector('.ThreeD__card');
    //     let bounds;
        
    //     function rotateToMouse(e) {
    //       const mouseX = e.clientX;
    //       const mouseY = e.clientY;
    //       const leftX = mouseX - bounds.x;
    //       const topY = mouseY - bounds.y;
    //       const center = {
    //         x: leftX - bounds.width / 2,
    //         y: topY - bounds.height / 2
    //       }
    //       const distance = Math.sqrt(center.x**2 + center.y**2);
          
    //       $card.style.transform = `
    //         rotate3d(
    //           ${center.y / 100},
    //           ${-center.x / 100},
    //           0,
    //           ${Math.log(distance)* 2}deg
    //         )
    //       `;
    //     }
        
    //     $card.addEventListener('mouseenter', () => {
    //       bounds = $card.getBoundingClientRect();
    //       document.addEventListener('mousemove', rotateToMouse);
    //     });
        
    //     $card.addEventListener('mouseleave', () => {
    //       document.removeEventListener('mousemove', rotateToMouse);
    //       $card.style.transform = '';
    //       $card.style.background = '';
    //     });        
    // }
    render() {
        return (
            <div data-slide="1" className="row mx-0">
                <div className="col-md-1"></div>
                <div className="col-md-5 pt-9 px-lg-0">
                    <TitleSubtitleDescription
                        heading="OWLSENSE"
                        subHeading="Cutting Edge Open Source Intelligence  Monitoring and Analysis Software"
                        description="
                            OwlSense recognizes the critical importance of generating insights from publicly available information; hence, we designed a cutting-edge OSINT solution, an all in one intelligence monitoring system, by which we can help uncovering online open source intelligence quickly and efficiently and turn the retrieved information into actionable intelligence by automatic analysis and processing. The system proposed here is designed to provide an end-to-end solution for lawful organizations. 
                            <br><br>                            
                            Our system includes powerful collection from various web-based sources automatically and within minimal time. Based on requirements we can also provide tools for social engineering and active engagements, which allow extracting additional information from targets. 
                            <br><br>                            
                            OwlSense enables users to monitor and analyse vast amounts of information from across the web, including forums, news websites, social media websites, as well as other public and open sources. We offer a composite system consisting of a WEBINT solution which includes full network monitoring module based on all major search engines and almost all mainstream national websites and regional websites as well as specified target websites. 
                            <br><br>                            
                            The solution offers a range of features including <span class='text-info-2'>Target Profiling</span> and <span class='text-info-2'>Surveillance</span>, <span class='text-info-2'>Deep Analysis</span>, <span class='text-info-2'>Powerful Crawling</span> and great visualization options.
                        "
                        />
                </div>
                <div className="col-md-6 pr-0 pt-4 pl-lg-2 pl-0 align-self-center">
                    <div className="row mx-0 align-items-center justify-content-end">
                        {this.state && this.state.parts.map((part, i) => (
                            <div
                                key={i} 
                                data-aos="zoom-out"
                                data-aos-delay={part.delay}
                                data-aos-duration="1000" 
                                className="col-1 px-0"><img src={part.owl} className="w-100" alt="" /></div>
                        ))}
                    </div>
                    {/* <div className="position-relative">
                        <img src={owl} alt="" className="w-100" />
                    </div> */}
                </div>
            </div>
        )
    }
}
