import React, { Component } from 'react'
import bg from '../assets/img/future.png'

export default class Future extends Component {
    render() {
        return (
            <div data-slide="6" id="hero-container" className="position-relative" style={{ background: `url(${bg})` }}>
                <div id="hero-body" className="flex-wrap align-items-start text-center position-relative py-5 h-lg-100vh h-75vh">
                    <div className="container px-0 text-white">
                        <div className="row mx-0 justify-content-center align-items-start">
                            <div className="col-md-12 px-0">
                                <h4 
                                    data-aos="zoom-out"
                                    data-aos-delay="100"
                                    data-aos-duration="700" 
                                    className="text-lg-h1 px-lg-4 px-0 pt-lg-4 pt-0 playfair-display-bold text-info-2">FUTURE OF INTELLIGENCE</h4>
                                <h4 
                                    data-aos="zoom-out"
                                    data-aos-delay="400"
                                    data-aos-duration="700" 
                                    className="px-lg-4 px-0 playfair-display-bold">GATHERING STARTS HERE</h4>
                            </div>
                        </div>
                    </div>                     
                </div>
            </div>
        )
    }
}
