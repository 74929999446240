import React, { Component } from 'react'
import propagandaInvestigation from '../assets/img/rapideye-services/propaganda-investigation.png'
import crimeAnalytics from '../assets/img/rapideye-services/crime-analytics.png'
import databaseIntegration from '../assets/img/rapideye-services/database-integration.png'
import geoMapping from '../assets/img/rapideye-services/geo-mapping.png'
import lawfulInterception from '../assets/img/rapideye-services/lawful-interception.png'
import lawOrder from '../assets/img/rapideye-services/law-order.png'

export default class RapideyeServices extends Component {
    state = {
        services: [
            { bg: lawOrder, title: "<span class='text-info-2'>LAW AND </span> ORDER <br> MAINTENENCE" },
            { bg: databaseIntegration, title: "<span class='text-info-2'> NATIONAL </span> <br> DATABASE <br> INTEGRATION" },
            { bg: geoMapping, title: "<span class='text-info-2'>CRIME </span> INVESTIGATION <br> & <br> GEO <span class='text-info-2'>MAPPING </span>" },
            { bg: crimeAnalytics, title: "<span class='text-info-2'>CRIME </span> ANALYTICS" },
            { bg: lawfulInterception, title: "<span class='text-info-2'>LAWFUL </span> INTERCEPTION" },
            { bg: propagandaInvestigation, title: "<span class='text-info-2'>PROPAGANDA </span> <br> INVESTIGATION" },
        ]
    }
    render() {
        return (
            <div data-slide="6" className="container-fluid px-0 py-4">
                <div className="container text-center pb-5">
                    <h1 className="playfair-display-bold display-4 d-none d-lg-block text-white">Our Services</h1>
                    <h1 className="playfair-display-bold d-block d-lg-none text-white">Our Services</h1>
                </div>
                <div className="row mx-0">
                    {this.state && this.state.services.map((service, i) => (
                        <div
                            key={i}
                            data-aos="zoom-in"
                            data-aos-delay="300" 
                            data-aos-duration="500" 
                            className="col-md-4 px-0">
                            <div className="position-relative gallery-container overflow-hidden">
                                <img src={service.bg} width="100%" alt="" />
                                <h2 className="d-none d-lg-flex overlay text-white lh-125 montserrat-semibold text-center align-items-center justify-content-center mb-0 px-5" style={{ bottom: "0px" }}>
                                        <span
                                            dangerouslySetInnerHTML={{ __html: service.title }}></span>
                                </h2>
                                <h5 className="d-flex d-lg-none overlay text-white lh-125 montserrat-semibold text-center align-items-center justify-content-center mb-0 px-0" style={{ bottom: "0px" }}>
                                        <span
                                            dangerouslySetInnerHTML={{ __html: service.title }}></span>
                                </h5>
                            </div>
                        </div>

                    ))}

                </div>
                
            </div>
        )
    }
}
