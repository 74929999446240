import React, { Component } from 'react'
import $ from 'jquery'
import bg from '../assets/img/national-security/bg-2.png'
import '../assets/css/two-tone-card.css'

import audioForensicCyan from '../assets/img/national-security/audio-forensic-cyan.svg'
import audioForensicWhite from '../assets/img/national-security/audio-forensic-white.svg'
import cdrIntereptionCyan from '../assets/img/national-security/cdr-intereption-cyan.svg'
import cdrIntereptionWhite from '../assets/img/national-security/cdr-intereption-white.svg'
import darkwebCyan from '../assets/img/national-security/darkweb-cyan.svg'
import darkwebWhite from '../assets/img/national-security/darkweb-white.svg'
import dataMonitoringCyan from '../assets/img/national-security/data-monitoring-cyan.svg'
import dataMonitoringWhite from '../assets/img/national-security/data-monitoring-white.svg'
import emotionAnalysisCyan from '../assets/img/national-security/emotion-analysis-cyan.svg'
import emotionAnalysisWhite from '../assets/img/national-security/emotion-analysis-white.svg'
import fakeNewsCyan from '../assets/img/national-security/fake-news-cyan.svg'
import fakeNewsWhite from '../assets/img/national-security/fake-news-white.svg'
import frsCyan from '../assets/img/national-security/frs-cyan.svg'
import frsWhite from '../assets/img/national-security/frs-white.svg'
import incidentResponsesCyan from '../assets/img/national-security/incident-responses-cyan.svg'
import incidentResponsesWhite from '../assets/img/national-security/incident-responses-white.svg'
import satelliteInterceptionCyan from '../assets/img/national-security/satellite-interception-cyan.svg'
import satelliteInterceptionWhite from '../assets/img/national-security/satellite-interception-white.svg'
import sentimentCyan from '../assets/img/national-security/sentiment-cyan.svg'
import sentimentWhite from '../assets/img/national-security/sentiment-white.svg'
import threatInvestigationCyan from '../assets/img/national-security/threat-investigation-cyan.svg'
import threatInvestigationWhite from '../assets/img/national-security/threat-investigation-white.svg'
import nationalDatabasesCyan from '../assets/img/national-security/national-databases-cyan.svg'
import nationalDatabasesWhite from '../assets/img/national-security/national-databases-white.svg'
import CyanBorderedCard from './widgets/CyanBorderedCard'

export default class NationalSecurity extends Component {
    state = {
        list: [
            { width:"47px", imgCyan: dataMonitoringCyan, imgWhite: dataMonitoringWhite, title: "OPEN SOURCE DATA MONITORING" },
            { width:"52px", imgCyan: cdrIntereptionCyan, imgWhite: cdrIntereptionWhite, title: "CDR INTERCEPTION" },
            { width:"52px", imgCyan: frsCyan, imgWhite: frsWhite, title: "FRS & IMAGE FORENSICS" },
            { width:"47px", imgCyan: threatInvestigationCyan, imgWhite: threatInvestigationWhite, title: "TERRORISM AND THREAT INVESTIGATION" },
            { width:"47px", imgCyan: darkwebCyan, imgWhite: darkwebWhite, title: "DARKWEB & LEAKED DATABASES MONITORING" },
            { width:"47px", imgCyan: nationalDatabasesCyan, imgWhite: nationalDatabasesWhite, title: "CENTRAL PLATFORM FOR NATIONAL DATABASES" },
            { width:"52px", imgCyan: sentimentCyan, imgWhite: sentimentWhite, title: "SENTIMENT AND NARRATIVE ANALYSIS" },
            { width:"47px", imgCyan: emotionAnalysisCyan, imgWhite: emotionAnalysisWhite, title: "BEHAVIOURAL AND EMOTION ANALYSIS" },
            // { width:"52px", imgCyan: satelliteInterceptionCyan, imgWhite: satelliteInterceptionWhite, title: "SATELLITE INTERCEPTION" },
            { width:"47px", imgCyan: audioForensicCyan, imgWhite: audioForensicWhite, title: "AUDIO FORENSIC" },
            { width:"47px", imgCyan: fakeNewsCyan, imgWhite: fakeNewsWhite, title: "FAKE NEWS AND PROPAGANDA INVESTIGATION" },
            { width:"47px", imgCyan: incidentResponsesCyan, imgWhite: incidentResponsesWhite, title: "INCIDENT RESPONSES" },
        ]
    }
    componentDidMount() {
        let { params } = this.props

        if (params.section == "national-security") {
            $('html, body').animate({
                scrollTop: $("#nationalsecurity").offset().top - $("#navbar").outerHeight() + 10
            }, 699)              
        }
    }    
    render() {
        return (
            <div data-slide="2" id="hero-container"  className="py-5 mt-5" style={{ background: `url(${bg})` }}>
                <div id="nationalsecurity" className="container w-100 py-5 px-lg-0">
                    <div className="row mx-0 justify-content-center py-lg-5 py-0">
                        <div className="col-lg-9 mb-4 px-lg-0">
                            <h2 
                                data-aos="fade-up"
                                data-aos-anchor-placement="top-bottom"
                                data-aos-delay="100"
                                data-aos-duration="500" 
                                className="display-lg-4 text-white playfair-display-semibold text-center">How We Serve <span className="playfair-display-semibold text-info-2">National Security?</span></h2>
                            <p 
                                data-aos="fade-up"
                                data-aos-anchor-placement="top-bottom"
                                data-aos-delay="300"
                                data-aos-duration="500"
                                className="text-secondary medium text-center mt-3 px-3 px-lg-0 lh-175">
                                We bring together scattered information from different sources to provide interconnected and significant insights. We help investigation agencies to rapidly connect the hidden dots, find the underlying meaning to make accurate Mission Critical decisions. We provide National Level Security on basis of:
                            </p>
                        </div>
                    </div>                
                    <div className="row mx-0">
                        {this.state && this.state.list.map((item, i) => (
                            <CyanBorderedCard 
                                key={i}
                                imgCyan={item.imgCyan} 
                                imgWhite={item.imgWhite}
                                title={item.title} 
                                width={item.width}
                            />
                        ))}                        
                    </div>

                </div>
            </div>
        )
    }
}
