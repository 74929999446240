import React, { Component } from 'react'
import bg from '../../assets/video/owlsense.png'
import play from '../../assets/img/play-btn.png'
import $ from 'jquery'
import thumbnail from '../../assets/img/thumbnails/owlsense.png'
import dotsRight from '../../assets/img/dots3.svg'
import dotsLeft from '../../assets/img/dots2.svg'

import '../../assets/css/pulsing-button.css'
import VideoPreview from '../modal/VideoPreview'
import video from '../../assets/video/owlsense.mp4'

export default class Owlsense extends Component {
    playVideo(id) {
        $("#lightbox").fadeIn(1000);  
        setTimeout(() => {
            $(id).trigger('play')                   
        }, 999);
    }    
    render() {
        return (
            <div data-slide="3" className="container text-center px-0 py-5 py-lg-0 my-md-5">
            <VideoPreview video={video} />
                <div className="row mx-0 justify-content-center">
                    <div className="col-md-10 position-relative px-0">
                        <div
                            className="position-relative z-1000 p-0 scale-lg-9 scale-1">
                            <img
                                className="p-0"
                                data-aos="zoom-out"
                                data-aos-duration="500" 
                                data-aos-delay="500"   
                                src={thumbnail} width="100%" class="h-lg-auto h-55vh" alt="" /> 
                            <div className="position-absolute bg-overlay-2 d-flex justify-content-center align-items-center top-0 left-0 w-100 h-100">
                                {/* <button 
                                    data-aos="zoom-in"
                                    data-aos-duration="500" 
                                    data-aos-delay="500"  
                                    onClick={() => this.playVideo("#intro-video")} 
                                    className="btn border-white border-w4 btn-circle btn-circle-xl z-2000 position-relative">
                                    <i className="mdi mdi-play text-white"></i>
                                </button>    */}
                                <div class="bg"></div>
                                <div class="button"><a                                     
                                    onClick={() => this.playVideo("#intro-video")} 
                                    href="javascript:void(0)"><i class="mdi mdi-play mdi-48px" aria-hidden="true"></i></a></div>                                                                            
                            </div>
                        </div>
                        {/* BORDERS */}
                        <div
                            className="position-absolute top-0 right-0 translateY-lg-0 translateY--35px">
                            <img
                                data-aos="zoom-out"
                                data-aos-duration="1000" 
                                data-aos-delay="1000"   
                                src={dotsRight} width="150px" alt="" />
                        </div>
                        <div
                            className="position-absolute bottom-0 left-0 translateY-lg-0 translateY-35px">
                            <img 
                                data-aos="zoom-out"
                                data-aos-duration="1000" 
                                data-aos-delay="1000"  
                                src={dotsLeft} width="150px" alt="" />
                        </div>
                        {/* <div
                            data-aos="zoom-in-down"
                            data-aos-duration="500" 
                            className="w-25 h-50 bg-primary-overlay position-absolute top-0"></div>   */}
                    </div>                        
                </div>                
            </div>            
        )
    }
}
