import React, { Component } from 'react'

const CapibilitiesTabContext = React.createContext()

export class CapibilitiesTabProvider extends Component {
    state = {        
        tab: {
            name: "Threat Intelligence",
            value: "threat_intelligence"
        }
    }
    
    setTab = (tab) => {
        this.setState({
            tab
        })
    }

    render() {
        const { tab } = this.state
        const { setTab } = this
        return(
            <CapibilitiesTabContext.Provider value={{
                tab,
                setTab
            }}>
                {this.props.children}
            </CapibilitiesTabContext.Provider>
        )
    }
}

export default CapibilitiesTabContext