import React, { Component } from 'react'
import bg from '../assets/img/5step-process/bg.png'

import analysis from '../assets/img/5step-process/analysis.svg'
import dataCollection from '../assets/img/5step-process/data-collection.svg'
import dataProcessing from '../assets/img/5step-process/data-processing.svg'
import hetrogeneousDatabase from '../assets/img/5step-process/hetrogeneous-database.svg'
import reporting from '../assets/img/5step-process/reporting.svg'
import path from '../assets/img/5step-process/path.svg'
import gradientTransitionTop from '../assets/img/gradient-transition-top.svg'
import gradientTransitionBottom from '../assets/img/gradient-transition-bottom.svg'

import 'jquery-ui-dist/jquery-ui'
import $ from 'jquery'

export default class FiveStepProcess extends Component {

    componentDidMount() {
        $( function() {
            $( ".draggable" ).draggable();
        })              
    }

    render() {
        return (
            <div data-slide="3" id="hero-container"  className="container-fluid position-relative" style={{ background: `url(${bg})`, paddingTop: "10rem", paddingBottom: "12rem" }}>
                <img src={gradientTransitionBottom} className="w-100 bottom-0 left-0 position-absolute" alt="" />
                <img src={gradientTransitionTop} className="w-100 top-0 left-0 position-absolute" alt="" />
                <div id="hero-body" className="d-none d-lg-block" style={{ height: "1000px"}}>
                    <div className="row mx-0 w-100 h-100">
                        <div className="col-lg-12 px-0 bg-align-self-center h-100 border border w-100">
                            <div className="container h-100 px-0 position-relative border w-100">
                                <h1 
                                    style={{left: "430.771px", top: "391.75px" }}
                                    className="position-absolute draggable text-white playfair-display-bold d-inline-block text-center display-4 mt-5">
                                    Our <span className="playfair-display-bold text-cyan">5-Step</span>
                                    <br /> Process
                                </h1>                                    
                                <img src={path} width="660px" className="position-absolute draggable border" style={{ left: "235.896px", top: "170px" }} alt="" />
                                <img src={analysis} width="120px" className="position-absolute draggable " style={{ left: "325.531px", top: "755px" }} alt="" />
                                <img src={dataCollection} width="120px" className="draggable position-absolute" style={{ left: "820.771px", top: "377.75px" }} alt="" />
                                <img src={dataProcessing} width="120px" className="draggable position-absolute" style={{ left: "701.094px", top: "764px" }} alt="" />
                                <img src={hetrogeneousDatabase} width="120px" className="draggable position-absolute" style={{ left: "511.438px", top: "117.458px" }} alt="" />
                                <img src={reporting} width="120px" className="draggable position-absolute" style={{ left: "183.438px", top: "351.406px" }} alt="" />
                                
                                <div className="d-inline-block draggable position-absolute" style={{ left: "504.688px", top: "258.656px" }}>
                                    <h6 className="text-white montserrat-semibold">HETEROGENEOUS <br /> DATABASE</h6>
                                    <p className="text-secondary small">Specify where to find <br /> information and fetch.</p>
                                </div>
                                <div className="d-inline-block draggable position-absolute" style={{ left: "869.021px", top: "516px" }}>
                                    <h6 className="text-white montserrat-semibold">DATA COLLECTION</h6>
                                    <p className="text-secondary small">Get relevant data from <br /> identified source.</p>
                                </div>
                                <div className="d-inline-block draggable position-absolute" style={{ left: "832.359px", top: "787.344px" }}>
                                    <h6 className="text-white montserrat-semibold">DATA PROCESSING</h6>
                                    <p className="text-secondary small">Process and clean the acquired <br /> data and get the meaningful <br /> information.</p>
                                </div>
                                <div className="d-inline-block draggable position-absolute text-right"  style={{ left: "100.344px", top: "787.344px" }}>
                                    <h6 className="text-white montserrat-semibold">ANALYSIS</h6>
                                    <p className="text-secondary small">Join the data acquired from <br /> multiple sources and correlate <br /> to get insights as required.</p>
                                </div>
                                <div className="d-inline-block draggable position-absolute text-right"  style={{ left: "115.375px", top: "489.344px" }}>
                                    <h6 className="text-white montserrat-semibold">REPORTING</h6>
                                    <p className="text-secondary small">Create a final report.</p>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>

                <div className="container d-lg-none d-block">
                    <h2 
                        className="text-white playfair-display-bold text-center pb-5"> Our <span className="playfair-display-bold text-cyan">5-Step</span> Process
                    </h2>                       
                    <div className="row mx-0">
                        <div className="col-lg-4 col-12 px-lg-5 px-3 py-3 text-center border-radius-5">
                            <img src={hetrogeneousDatabase} className="img__white" width="90px" alt="" />
                            <h6 className="text-white montserrat-semibold mt-4">HETEROGENEOUS DATABASE</h6>
                            <p className="text-secondary small">Specify where to find information and fetch.</p>
                        </div>  
                        <div className="col-lg-4 col-12 px-lg-5 px-3 py-3 text-center border-radius-5">
                            <img src={dataCollection} className="img__white" width="90px" alt="" />
                            <h6 className="text-white montserrat-semibold mt-4">Data COLLECTION</h6>
                            <p className="text-secondary small">Get relevant data from identified source.</p>
                        </div>  
                        <div className="col-lg-4 col-12 px-lg-5 px-3 py-3 text-center border-radius-5">
                            <img src={dataProcessing} className="img__white" width="90px" alt="" />
                            <h6 className="text-white montserrat-semibold mt-4">DATA PROCESSING</h6>
                            <p className="text-secondary small">Process and clean the acquired data and get the meaningful information.</p>
                        </div>  
                        <div className="col-lg-4 col-12 px-lg-5 px-3 py-3 text-center border-radius-5">
                            <img src={analysis} className="img__white" width="90px" alt="" />
                            <h6 className="text-white montserrat-semibold mt-4">ANALYSIS</h6>
                            <p className="text-secondary small">Join the data acquired from multiple sources and correlate to get insights as required.</p>
                        </div>  
                        <div className="col-lg-4 col-12 px-lg-5 px-3 py-3 text-center border-radius-5">
                            <img src={reporting} className="img__white" width="90px" alt="" />
                            <h6 className="text-white montserrat-semibold mt-4">REPORTING</h6>
                            <p className="text-secondary small">Creata a final report.</p>
                        </div>  
                                    
                    </div>
                </div>                    
            </div>
        )
    }
}
