import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../assets/css/button.css'
import bg from '../../assets/img/hero/perception-management-tool.png'

import facebook from '../../assets/img/social/facebook-info.png'
import instagram from '../../assets/img/social/instagram-info.png'
import twitter from '../../assets/img/social/twitter-info.png'
import linkedin from '../../assets/img/social/linkedin-info.png'

export default class HeroPerceptionManagementTool extends Component {
    render() {
        return (
            <div data-slide="0" id="hero-container" className="position-relative" style={{ background: `url(${bg}) repeat fixed 100%` }}>
                <div
                    style={{ background: "linear-gradient(180deg, rgba(1, 22, 39, 0) 0%, #01182B 76.64%, #011728 100%)" }} 
                    className="bottom-0 left-0 position-absolute w-100 h-25"></div>
                <div 
                    id="hero-body" 
                    className="flex-wrap text-center bg-dark-opaque position-relative">
                    <div className="container-fluid text-white">
                        <div className="row mx-0">
                            <div className="col-md-1 d-none d-lg-block">
                                {/* <a href="https://twitter.com/rapidevd" className="btn-block mb-3"><img src={twitter} width="22px" alt="" /></a>
                                <a href="https://www.instagram.com/rapidev.pk" className="btn-block mb-3"><img src={instagram} width="22px" alt="" /></a>
                                <a href="https://www.facebook.com/Rapidev.Official/" className="btn-block mb-3"><img src={facebook} width="22px" alt="" /></a>
                                <a href="https://www.linkedin.com/company/rapidev/mycompany/" className="btn-block mb-3"><img src={linkedin} width="22px" alt="" /></a> */}
                            </div>
                            <div className="col-md-10 px-0 px-0 mt-5 mt-lg-0 pt-5 pt-lg-0">
                                <div className="container px-0 mt-5 mt-lg-0 pt-5 pt-lg-0">
                                    <div className="row mx-0 justify-content-center">
                                        <div className="col-md-12 px-md-5 px-0" style={{ marginTop: "0rem" }}>
                                            <h1 
                                                data-aos="zoom-out"
                                                data-aos-delay="500"
                                                data-aos-duration="1000" 
                                                className="mb-0 ls-2 text-uppercase playfair-display-bold display-lg-4">PERCEPTION MANAGEMENT TOOL</h1>
                                            <div className="row mx-0 justify-content-center">
                                                <div className="col-md-10 pt-2 px-0">
                                                    <p 
                                                        data-aos="fade-up"
                                                        data-aos-delay="700"
                                                        data-aos-duration="1000"
                                                        className="py-2">A Marketing Solution</p>
                                                </div>
                                                <div className="col-md-12 pt-4 px-lg-4 px-0">
                                                    <Link
                                                        data-aos="zoom-out"
                                                        data-aos-delay="1500"
                                                        data-aos-duration="1000"  
                                                        to="/reach-us" className="btn btn-info-2 px-5 py-3 ls-2 border-rounded"><span className="small">REQUST FOR DEMO</span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-1"></div>
                        </div>
                    </div>                     
                </div>
            </div>
        )
    }
}
