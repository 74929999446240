import React, { Component } from 'react'

import CapibilitiesTabContext, { CapibilitiesTabProvider } from '../context/CapibilitiesTabContext'
import NationalSecurityIntelligence from './capibilities/NationalSecurityIntelligence'
import PerceptionManagementIntelligence from './capibilities/PerceptionManagementIntelligence'
import Tabs from './capibilities/Tabs'
import ThreatIntelligence from './capibilities/ThreatIntelligence'

export default class OurCapabilities extends Component {
    render() {
        return (
            <div data-slide="5" className="container px-0 text-white pt-8">
                <h1 className="display-4 d-none d-lg-block playfair-display-bold text-center">Our Capabilities</h1>
                <h1 className="d-block d-lg-none playfair-display-bold text-center">Our Capabilities</h1>
                <div className="pb-10 mb-5">
                    <CapibilitiesTabProvider>
                        <Tabs />
                        <ThreatIntelligence />
                        <NationalSecurityIntelligence />
                        <PerceptionManagementIntelligence />
                    </CapibilitiesTabProvider>   
                </div>
            </div>
        )
    }
}
OurCapabilities.contextType = CapibilitiesTabContext