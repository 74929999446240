import React, { Component } from 'react'
import bg from '../assets/img/rapideye-information-sources/bg.png'

import trafficMonitoring from '../assets/img/rtms-propositions/traffic-monitoring.svg'
import userFriendlyMapping from '../assets/img/rtms-propositions/user-friendly-mapping.svg'
import voicePlayback from '../assets/img/rtms-propositions/voice-playback.svg'
import detailedReporting from '../assets/img/rtms-propositions/detailed-reporting.svg'
import enhancedSecurity from '../assets/img/rtms-propositions/enhanced-security.svg'


import detailedReportingWhite from '../assets/img/rtms-propositions/detailed-reporting-white.svg'
import detailedReportingCyan from '../assets/img/rtms-propositions/detailed-reporting-cyan.svg'

import enhancedSecurityWhite from '../assets/img/rtms-propositions/enhanced-security-white.svg'
import enhancedSecurityCyan from '../assets/img/rtms-propositions/enhanced-security-cyan.svg'

import processingAndAnalysisWhite from '../assets/img/rtms-propositions/processing-and-analysis-white.svg'
import processingAndAnalysisCyan from '../assets/img/rtms-propositions/processing-and-analysis-cyan.svg'

import trafficMonitoringWhite from '../assets/img/rtms-propositions/traffic-monitoring-white.svg'
import trafficMonitoringCyan from '../assets/img/rtms-propositions/traffic-monitoring-cyan.svg'

import userFriendlyMappingWhite from '../assets/img/rtms-propositions/user-friendly-mapping-white.svg'
import userFriendlyMappingCyan from '../assets/img/rtms-propositions/user-friendly-mapping-cyan.svg'

import voicePlaybackWhite from '../assets/img/rtms-propositions/voice-playback-white.svg'
import voicePlaybackCyan from '../assets/img/rtms-propositions/voice-playback-cyan.svg'

import gradientTransitionTop from '../assets/img/gradient-transition-top.svg'
import gradientTransitionBottom from '../assets/img/gradient-transition-bottom.svg'
import CyanBorderedCard from './widgets/CyanBorderedCard'

export default class RtmsUniqueValuePropositions extends Component {
    state = {
        list: [
            { width:"52px", imgCyan: trafficMonitoringCyan, imgWhite: trafficMonitoringWhite, title: "REAL-TIME CALLS AND TRAFFIC MONITORING" },
            { width:"52px", imgCyan: processingAndAnalysisCyan, imgWhite: processingAndAnalysisWhite, title: "PROCESSING AND ANALYSIS" },
            { width:"45px", imgCyan: voicePlaybackCyan, imgWhite: voicePlaybackWhite, title: "VOICE PLAYBACK" },
            { width:"40px", imgCyan: detailedReportingCyan , imgWhite: detailedReportingWhite, title: "DETAILED REPORTING" },
            { width:"52px", imgCyan: userFriendlyMappingCyan, imgWhite: userFriendlyMappingWhite, title: "USER FRIENDLY MAPPING" },
            { width:"52px", imgCyan: enhancedSecurityCyan, imgWhite: enhancedSecurityWhite, title: "ENHANCED SECURITY" }
        ]
    }      
    render() {
        return (
            <div data-slide="2" id="hero-container"  className="container-fluid py-10 mt-5" style={{ background: `url(${bg})`}}>
                <img src={gradientTransitionBottom} className="w-100 bottom-0 left-0 position-absolute" alt="" />
                <img src={gradientTransitionTop} className="w-100 top-0 left-0 position-absolute" alt="" />                
                <div id="hero-body" style={{ height: "auto" }}>
                    <div className="w-100 text-center">
                        <div className="container text-center pt-lg-5">
                            <div className="row mx-0 justify-content-center">
                                <div className="col-lg-9">
                                    <h2 className="text-lg-h1 lh-150 text-white playfair-display-bold">RTMS - <span className="playfair-display-bold text-info-2">Unique Value Propositions</span></h2>
                                    <p className="text-secondary font-weight-light medium mt-3 ls-1">
                                        Rapid Thuraya Monitoring System provides us with some of the the unique value propositions such as
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="container my-5">
                            <div className="row mx-0">
                                {this.state && this.state.list.map((item, i) => (
                                    <CyanBorderedCard
                                        colLg={4}
                                        colSm={12}
                                        key={i}
                                        imgCyan={item.imgCyan} 
                                        imgWhite={item.imgWhite}
                                        title={item.title} 
                                        width={item.width}
                                    />
                                ))}                        
                            </div>     
                        </div>                  
                    </div>                 
                </div>
            </div>
        )
    }
}
