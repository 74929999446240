import React, { Component } from 'react'
import Orbit from './widgets/Orbit'
import line from '../assets/img/owlsense-capacities/lines.svg'

import 'jquery-ui-dist/jquery-ui'
import $ from 'jquery'

export default class OwlsenseCapacities extends Component {

    componentDidMount() {
        $( function() {
            $( ".draggable" ).draggable();
        })              
    }    
    render() {
        return (
            <div data-slide="7" className="container d-none d-lg-block px-0 py-5 my-5">
                <div className="w-100 position-relative my-5" style={{height: "1000px"}}>
                    <img src={line} className="position-absolute w-100" alt="" style={{left: "13px", top: "22.9792px"}} />
                    <div className="position-absolute" style={{ left: "187.333px", top: "-25.6667px" }}>
                        <Orbit />
                    </div>                    
                    <h1 
                        data-aos="zoom-out"
                        data-aos-delay="100"
                        data-aos-duration="700" 
                        className="playfair-display-bold text-secondary display-4 position-absolute d-inline-block" style={{left: "42.6562px", top: "9.64062px"}}>
                            <span className="text-cyan playfair-display-bold">OWLSENSE</span> <br />CAPACITIES</h1>
                    <p 
                        data-aos-anchor-placement="bottom-bottom"
                        data-aos="zoom-out"
                        data-aos-delay="100"
                        data-aos-duration="1000" 
                        className="position-absolute text-light-blue-2 text-center" style={{left: "561.021px", top: "128px" }}><span className="text-cyan">Categorization</span> and <span className="text-cyan">Blocking <br /> Censorable Content</span></p>
                    <p 
                        data-aos-anchor-placement="bottom-bottom"
                        data-aos="zoom-out"
                        data-aos-delay="200"
                        data-aos-duration="1000" 
                        className="position-absolute text-light-blue-2 text-center" style={{left: "352.354px", top: "287.333px" }}>Identifying <span className="text-cyan">External Threats</span></p>
                    <p 
                        data-aos-anchor-placement="bottom-bottom"
                        data-aos="zoom-out"
                        data-aos-delay="400"
                        data-aos-duration="1000"
                        className="position-absolute text-light-blue-2 text-center" style={{left: "358.667px", top: "416.667px" }}><span className="text-cyan">IP Logging</span></p>
                    <p 
                        data-aos-anchor-placement="bottom-bottom"
                        data-aos="zoom-out"
                        data-aos-delay="300"
                        data-aos-duration="1000"
                        className="position-absolute text-light-blue-2 text-center" style={{left: "195px", top: "542px" }}>Make <span className="text-cyan">Open Source Data <br /> Discovery</span> Effortless</p>
                    <p 
                        data-aos-anchor-placement="bottom-bottom"
                        data-aos="zoom-out"
                        data-aos-delay="100"
                        data-aos-duration="1000"
                        className="position-absolute text-light-blue-2 text-center" style={{left: "406px", top: "673px" }}>Be Aware Of <span className="text-cyan">Real World <br /> Threats</span></p>
                    <p 
                        data-aos-anchor-placement="bottom-bottom"
                        data-aos="zoom-out"
                        data-aos-delay="200"
                        data-aos-duration="1000"
                        className="position-absolute text-light-blue-2 text-center" style={{left: "752.667px", top: "729.667px" }}>Enable Real Time <span className="text-cyan">Incident <br /> Responses</span></p>
                    <p 
                        data-aos-anchor-placement="bottom-bottom"
                        data-aos="zoom-out"
                        data-aos-delay="400"
                        data-aos-duration="1000"
                        className="position-absolute text-light-blue-2 text-center" style={{left: "879.667px", top: "470.667px" }}>Find Out Accidental <span className="text-cyan">Leaks Of <br /> Sensitive Information</span></p>
                    <p 
                        data-aos-anchor-placement="bottom-bottom"
                        data-aos="zoom-out"
                        data-aos-delay="500"
                        data-aos-duration="1000"
                        className="position-absolute text-light-blue-2 text-center" style={{left: "800.333px", top: "312.333px" }}>Gather And Understand <span className="text-cyan">Public <br /> Sentiemnet, React, And Respond</span></p>


                </div>
            </div>
        )
    }
}
