import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import bg from '../../assets/img/bg/bg-gradients.png'

export default class TitleDescButton extends Component {
    render() {
        let { heading, subHeading } = this.props
        return (
            <div data-slide="-1"  className="container my-0 text-center pt-lg-10 pt-5 pb-4">
                <div className="row mx-0 justify-content-center pt-10">
                    <div className="col-lg-7">
                        <h1 
                            data-aos="fade-up"
                            data-aos-delay="300"
                            data-aos-duration="500"
                            className="display-lg-4 playfair-display-bold text-white">{heading}</h1>
                        <p 
                            data-aos="fade-up"
                            data-aos-delay="500"
                            data-aos-duration="500"
                            dangerouslySetInnerHTML={{ __html: subHeading }}
                            className="medium text-light-2 py-4 my-0 lh-175 font-weight-light"></p>           
                                
                        <Link
                            data-aos="zoom-out"
                            data-aos-delay="800"
                            data-aos-duration="1000" 
                            to="/" className="btn btn-info-2 px-5 py-3 ls-2 my-4 shadow-cyan border-rounded">
                            <span className="small">REQUEST FOR DEMO</span>
                        </Link>                                                     
                    </div>
                </div>
            </div>
        )
    }
}
