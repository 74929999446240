import React, { Component } from 'react'
import CapibilitiesTabContext from '../../context/CapibilitiesTabContext'

import crimeGeomappingGif from '../../assets/img/gifs/noncopyright/Crime Geomapping.gif'
import propagandaInvestigationGif from '../../assets/img/gifs/noncopyright/propaganda investigation 1.gif'
import threatInvestigationGif from '../../assets/img/gifs/noncopyright/terrorism and threat investigation.gif'

import dots from '../../assets/img/dots2.svg'
import bg from '../../assets/img/capibilities/bg.png'
import GifCardDesign from '../widgets/GifCardDesign'

export default class ThreatIntelligence extends Component {
    state = {
        capibilities: [
            { img: threatInvestigationGif, sub: "Rise above the chaos and find the source!", value: "terrorism_threat_investigation", title: "Terrorism and Threat Investigation", desc: "Find out real time terrorism incidents around the world, identify external threats, discover leaked databases & sensitive information, connect hidden dots to uncover the source of threat."},
            { img: propagandaInvestigationGif, sub: "who, when, where & why?", value: "propaganda_investigation", title: "Propaganda Investigation", desc: "Find out propagandas across social media, discover the source of hashtags & the propagators, identify bots, sock puppets and fake news, generate counter narrative."},
            { img: crimeGeomappingGif, sub: "Mapping your way to source of crime!", value: "crime_geomapping", title: "Crime Geomapping ", desc: "Generate crime patterns, crime distribution across cities, discover location of suspects, find highest crime rated places, uncover location of hate speech social media propagators across world."},
        ]
    }
    render() {
        const { tab } = this.context
        return (
            tab.value == "threat_intelligence" ?
                <div> 
                    {this.state && this.state.capibilities.map((capibility, i) => (
                        <GifCardDesign 
                            key={i}
                            img={capibility.img}
                            sub={capibility.sub}
                            value={capibility.value}
                            title={capibility.title}
                            desc={capibility.desc}
                            />

                    ))}
                </div>
            : null
        )
    }
}
ThreatIntelligence.contextType = CapibilitiesTabContext
