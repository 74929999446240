import React, { Component } from 'react'
import Future from '../components/Future'
import HeroOwlsense from '../components/hero/HeroOwlsense'
import IntelligenceAction from '../components/IntelligenceAction'
import OwlsenseIntro from '../components/intro/Owlsense'
import UniqueProposition from '../components/UniqueProposition'
import OwlsenseVideo from '../components/video/Owlsense'
import WhatisOwlsense from '../components/WhatisOwlsense'

import owlsenseCapacities from '../assets/img/owlsense-capacities.png'
import ContactInfo from '../components/ContactInfo'
import TitleDescButton from '../components/text/TitleDescButton'
import horizontalLine from '../assets/img/horizontal-line.svg'

import IndicatorsContext, { IndicatorsProvider } from '../context/IndicatorsContext'
import Indicators from '../components/Indicators'

import $ from 'jquery'
import OwlsenseCapacities from '../components/OwlsenseCapacities'

export default class Owlsense extends Component {

    componentDidMount() {
        document.title = "Owlsense | Owlsense"
        $('html, body').animate({
            scrollTop: 0
        }, 1000)
    }    
    render() {
        return (
            <div className="container-fluid px-0">                
                <IndicatorsProvider>
                    
                    {/* Indicators showing... */}
                    <Indicators />

                    <HeroOwlsense />
                    <OwlsenseIntro />
                    <WhatisOwlsense />
                    <OwlsenseVideo />
                    <UniqueProposition />
                    <IntelligenceAction />
                    <Future />
                    <OwlsenseCapacities />
                    {/* <div>
                        <div className="row mx-0 justify-content-center">
                            <div className="col-lg-10">
                                <img src={owlsenseCapacities} className="w-100" alt="" />
                            </div>
                        </div>
                    </div> */}
                    <ContactInfo />
                    {/* <TitleDescButton
                        heading="Want to know more?"
                        subHeading="Request a free demo to discover how <strong>OwlSense (Pvt.) Ltd.</strong> assures open-source/private intercepted data intelligence and delivers a validated, independent, impartial perspective on threats and investigations."
                    />
                    <div className="w-100 text-center pb-lg-10 pt-5">
                        <img src={horizontalLine} width="3px" height="77px" alt="Horizontal Line" />
                    </div>      */}
                </IndicatorsProvider>           
            </div>
        )
    }
}

Owlsense.contextType = IndicatorsContext
