import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../assets/css/button.css'
import bg from '../../assets/img/hero/home.png'

import facebook from '../../assets/img/social/facebook-info.png'
import instagram from '../../assets/img/social/instagram-info.png'
import twitter from '../../assets/img/social/twitter-info.png'
import linkedin from '../../assets/img/social/linkedin-info.png'
import gradient90Deg from '../../assets/img/gradient-90deg.png'
/* Gradient transition */


export default class HeroHome extends Component {
    render() {
        let { heading, description, logo } = this.props
        return (
            <div data-slide="0" id="hero-container" className="position-relative" style={{ background: `url(${bg}) repeat fixed 100%` }}>
                <div className="bottom-0 left-0 position-absolute w-100" style={{}}>
                    <img src={gradient90Deg} className="w-100" alt="" />
                </div>
                <div 
                    id="hero-body" 
                    className="flex-wrap text-center position-relative">
                    <div className="container-fluid text-white pt-5 mt-5">
                        <div className="row mx-0">
                            <div className="col-md-1 d-none d-lg-block">
                                {/* <a href="https://twitter.com/rapidevd" className="btn-block mb-3"><img src={twitter} width="22px" alt="" /></a>
                                <a href="https://www.instagram.com/rapidev.pk" className="btn-block mb-3"><img src={instagram} width="22px" alt="" /></a>
                                <a href="https://www.facebook.com/Rapidev.Official/" className="btn-block mb-3"><img src={facebook} width="22px" alt="" /></a>
                                <a href="https://www.linkedin.com/company/rapidev/mycompany/" className="btn-block mb-3"><img src={linkedin} width="22px" alt="" /></a> */}
                            </div>
                            <div className="col-md-10 px-0 px-lg-2">
                                <div className="container px-0 px-lg-2">
                                    <div className="row mx-0 justify-content-center">
                                        <div className="col-md-12 px-0 px-md-2" style={{ marginTop: "0rem" }}>
                                            <h1 
                                                data-aos="zoom-out"
                                                data-aos-delay="500"
                                                data-aos-duration="1000" 
                                                className="d-none d-lg-block mb-0 ls-2 text-uppercase petrona-bold">guaranteed, <i className="text-info-2 petrona-bold">interconnected insights</i> that uncover <i className="text-info-2 petrona-bold"> hidden meanings </i> of information</h1>
                                            <h4 
                                                data-aos="zoom-out"
                                                data-aos-duration="1000"
                                                className="d-block d-lg-none mb-0 ls-2 text-uppercase petrona-bold">guaranteed, <i className="text-info-2 petrona-bold">interconnected insights</i> that uncover <i className="text-info-2 petrona-bold"> hidden meanings </i> of information</h4>
                                            <div className="row mx-0 justify-content-center">
                                                <div className="col-md-10 pt-3 px-0">
                                                    <p 
                                                        data-aos="fade-up"
                                                        data-aos-delay="700"
                                                        data-aos-duration="1000"
                                                        className="py-2 medium px-4 segoe-ui px-lg-0">Enabling users to Monitor and Analyse vast amount of information from across the web, including Forums, Blogs, News, Websites, Social Media, Search Engines, Dark Web, Fusion Databases and many other Open and Close Sources.</p>
                                                </div>
                                                <div className="col-md-12 pt-4 px-4">
                                                    <Link
                                                        data-aos="zoom-out"
                                                        data-aos-delay="1500"
                                                        data-aos-duration="1000" 
                                                        to="/reach-us" className="btn btn-info-2 px-5 py-3 border-rounded ls-2 shadow-cyan"><span className="small">REQUST FOR DEMO</span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-1">
                                {/* <a href="javascript:void(0)" className="btn-block my-0 mdi mdi-record-circle-outline text-info-2"></a>
                                <a href="javascript:void(0)" className="btn-block my-0 mdi mdi-circle-medium text-info-2"></a>
                                <a href="javascript:void(0)" className="btn-block my-0 mdi mdi-circle-medium text-info-2"></a>
                                <a href="javascript:void(0)" className="btn-block my-0 mdi mdi-circle-medium text-info-2"></a> */}
                            </div>
                        </div>
                    </div>                     
                </div>
            </div>
        )
    }
}
